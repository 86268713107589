import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="w-full border-t border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 items-center justify-between">
        <div className="text-sm text-muted-foreground">
          © {new Date().getFullYear()} Spreadsheet Challenges
        </div>

        <nav className="flex items-center gap-6">
          <Link 
            to="/privacy" 
            className="text-sm text-muted-foreground hover:text-primary transition-colors"
          >
            Privacy
          </Link>
          <Link 
            to="/cookie-policy" 
            className="text-sm text-muted-foreground hover:text-primary transition-colors"
          >
            Cookies
          </Link>
          <Link 
            to="/aboutus" 
            className="text-sm text-muted-foreground hover:text-primary transition-colors"
          >
            About
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer; 