import React, { useState, useEffect, ReactNode } from 'react';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import UsernameSetup from './username-setup';
import { getApiUrl } from '../config/env';

interface UsernameRequirementProps {
  children: ReactNode;
}

export const UsernameRequirement: React.FC<UsernameRequirementProps> = ({ children }) => {
  const session = useSessionContext() as SessionContextType;
  const [loading, setLoading] = useState(true);
  const [hasUsername, setHasUsername] = useState(false);
  
  useEffect(() => {
    const checkUsername = async () => {
      if (session.loading || !session.accessTokenPayload) {
        return;
      }
      
      try {
        const userId = session.accessTokenPayload.sub;
        const response = await fetch(getApiUrl(`/api/users/${userId}/profile`), {
          credentials: 'include'
        });
        
        if (response.ok) {
          const data = await response.json();
          // If username is different from userId, it means user has set a custom username
          setHasUsername(data.username !== userId);
        }
      } catch (error) {
        console.error('Error checking username:', error);
      } finally {
        setLoading(false);
      }
    };
    
    checkUsername();
  }, [session]);
  
  const handleUsernameSetup = (username: string) => {
    setHasUsername(true);
  };
  
  if (session.loading || loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-64px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }
  
  if (!session.doesSessionExist) {
    return <>{children}</>;
  }
  
  if (!hasUsername) {
    return (
      <div className="container max-w-4xl py-12">
        <h1 className="text-2xl font-bold text-center mb-8">Welcome to Spreadsheet Challenges!</h1>
        <p className="text-center mb-8 text-muted-foreground">
          Before continuing, please choose a username that will be displayed to other users.
        </p>
        <UsernameSetup onComplete={handleUsernameSetup} />
      </div>
    );
  }
  
  return <>{children}</>;
};

export default UsernameRequirement; 