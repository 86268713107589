import React from "react";
import { Target, Users, Sparkles, Rocket, BookOpen, Trophy } from "lucide-react";
import Header from "../components/Header";
import GridBackground from "../components/GridBackground";
import { Card } from "../components/ui/card";

const AboutUs = () => {
  const features = [
    {
      icon: <Target className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Our Mission",
      description: "To provide an engaging platform for learning and mastering spreadsheet skills through practical challenges.",
    },
    {
      icon: <Users className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Community",
      description: "Join a growing community of spreadsheet enthusiasts, from beginners to experts.",
    },
    {
      icon: <Sparkles className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Innovation",
      description: "Constantly evolving platform with new challenges and features to enhance your learning experience.",
    },
  ];

  const values = [
    {
      icon: <BookOpen className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Learning-First",
      description: "We prioritize educational value in every challenge we create.",
    },
    {
      icon: <Trophy className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Excellence",
      description: "Striving for excellence in both our platform and our community.",
    },
    {
      icon: <Rocket className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Growth",
      description: "Supporting continuous improvement and skill development.",
    },
  ];

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="min-h-[calc(100vh-64px)] flex items-center justify-center py-16">
          <div className="w-full max-w-4xl mx-auto px-4">
            <Card className="overflow-hidden border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 backdrop-blur-xl">
              <div className="p-8">
                {/* Header */}
                <div className="text-center mb-12">
                  <h1 className="text-4xl font-bold mb-4">About Us</h1>
                  <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
                    Welcome to Spreadsheet Challenges, where we transform spreadsheet learning into an
                    engaging and rewarding experience. Our platform combines practical challenges with
                    competitive elements to make learning both fun and effective.
                  </p>
                </div>

                {/* Features */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6 text-center">What We Do</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {features.map((feature, index) => (
                      <div
                        key={index}
                        className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30"
                      >
                        <div className="mb-4">{feature.icon}</div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{feature.description}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Values */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6 text-center">Our Values</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {values.map((value, index) => (
                      <div
                        key={index}
                        className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30"
                      >
                        <div className="mb-4">{value.icon}</div>
                        <h3 className="text-lg font-semibold mb-2">{value.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{value.description}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Join Us */}
                <div>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30 text-center">
                    <h2 className="text-2xl font-semibold mb-4">Join Our Community</h2>
                    <p className="text-gray-600 dark:text-gray-400 mb-6">
                      Ready to start your journey? Join thousands of other spreadsheet enthusiasts and begin
                      solving challenges today.
                    </p>
                    <a
                      href="/auth"
                      className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-[#3eb489] dark:bg-[#4ade80] text-white hover:bg-[#35a07a] dark:hover:bg-[#3ade70] transition-colors"
                    >
                      <Users className="h-5 w-5" />
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>  
  );
};

export default AboutUs; 