import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getApiUrl } from '../config/env';
import ProblemTutorialOverlay from "./problem-tutorial-overlay";
import Editor from "../pages/Editor"; // Import the real Editor component
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Trophy, ArrowLeft } from "lucide-react";
import { Button } from "./ui/button";

const EditorTutorial: React.FC = () => {
  const [showTutorialOverlay, setShowTutorialOverlay] = useState(true);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [earnedScore, setEarnedScore] = useState(0);
  const navigate = useNavigate();
  const sessionContext = useSessionContext();

  // Mark tutorial as seen when user finishes or skips
  const handleDismissTutorial = async () => {
    console.log('Dismissing problem tutorial overlay');
    setShowTutorialOverlay(false);
    
    if (!sessionContext.loading && sessionContext.doesSessionExist) {
      try {
        const userId = sessionContext.userId;
        const response = await fetch(getApiUrl(`/api/users/${userId}/mark-problem-tutorial-seen`), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        
        if (response.ok) {
          console.log('Successfully marked problem tutorial as seen');
        } else {
          console.error('Failed to mark problem tutorial as seen:', await response.text());
        }
      } catch (error) {
        console.error('Error marking problem tutorial as seen:', error);
      }
    }
  };

  const handleFinish = () => {
    // Navigate to problems page when done
    navigate('/problems');
  };

  const handleProblemComplete = (score: number) => {
    setEarnedScore(score);
    setShowCompletionModal(true);
    
    // Auto redirect after delay
    setTimeout(() => {
      setShowCompletionModal(false);
      navigate('/problems');
    }, 4500);
  };

  return (
    <>
      <Dialog open={showCompletionModal} onOpenChange={setShowCompletionModal}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl">
              <Trophy className="h-6 w-6 text-yellow-500" />
              Problem Completed!
            </DialogTitle>
            <DialogDescription className="text-center space-y-2">
              <p className="text-lg">
                Congratulations! You've solved the problem and earned:
              </p>
              <p className="text-3xl font-bold text-primary">
                {earnedScore} points
              </p>
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center gap-4 mt-6">
            <Button
              variant="outline"
              onClick={() => navigate('/problems')}
              className="w-full"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Return
            </Button>
            <Button
              onClick={() => {
                setShowCompletionModal(false);
                navigate('/problems');
              }}
              className="w-full"
            >
              More Problems
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      
      {/* Use regular CSS classes that match the real Editor component */}
      <div className="relative">
        {/* The Editor component without any wrappers that might alter its appearance */}
        <Editor 
          tutorialMode={true} 
          fixedProblemId="1" 
          onFinish={handleFinish}
          onProblemComplete={handleProblemComplete}
        />
        
        {/* Tutorial Overlay */}
        {showTutorialOverlay && (
          <ProblemTutorialOverlay onDismiss={handleDismissTutorial} />
        )}
      </div>
    </>
  );
};

export default EditorTutorial;