import React from "react";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { Menu, X } from "lucide-react";
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import AuthButtons from "./auth-buttons";
import { ThemeToggle } from "./ui/theme-toggle";
import { cn } from "../lib/utils";

const Header: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const session = useSessionContext() as SessionContextType & {
    doesSessionExist: boolean;
    userId: string;
  };

  const menuItems = [
    { text: 'Problems', path: '/problems' },
    { text: 'Learning Paths', path: '/learning-paths' },
    { text: 'Challenges', path: '/challenges' },
    { text: 'Achievements', path: '/achievements' },
    { text: 'Leaderboard', path: '/leaderboard' },
    { text: 'Documentation', path: '/docs' },
    { text: 'Pricing', path: '/pricing' },
  ];

  return (
    <header className={cn(
      "fixed top-0 left-0 right-0 z-[100] w-full",
      "bg-background backdrop-blur-sm border-b border-border"
    )}>
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <Link 
            to="/" 
            className="text-2xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent hover:to-primary transition-colors"
          >
            SpreadsheetChallenges
          </Link>
          
          <nav className="hidden md:flex items-center gap-6">
            {menuItems.map((item) => (
              <Link 
                key={item.text} 
                to={item.path} 
                className="text-sm font-medium text-foreground hover:text-primary transition-colors relative group"
              >
                {item.text}
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-primary transition-all group-hover:w-full" />
              </Link>
            ))}
            {session.doesSessionExist && (
              <Link 
                to={`/profile/${session.userId}`} 
                className="text-sm font-medium text-foreground/80 hover:text-primary transition-colors relative group"
              >
                Profile
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-primary transition-all group-hover:w-full" />
              </Link>
            )}
            <div className="flex items-center gap-4 pl-4 border-l border-border">
              <AuthButtons />
              <ThemeToggle />
            </div>
          </nav>

          <div className="md:hidden flex items-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Menu className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="fixed inset-0 z-[101] md:hidden">
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setMobileMenuOpen(false)}
          />

          <div
            className="
              fixed right-0 top-0
              h-screen w-full
              bg-background/95 dark:bg-gray-900/95
              shadow-lg
              overflow-y-auto
              animate-in slide-in-from-right
            "
          >
            <div className="container mx-auto px-4 py-6">
              <div className="flex items-center justify-between mb-8">
                <Link 
                  to="/" 
                  className="text-2xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  SpreadsheetChallenges
                </Link>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <X className="h-6 w-6" />
                </Button>
              </div>

              <nav className="flex flex-col gap-8">
                {menuItems.map((item) => (
                  <Link
                    key={item.text}
                    to={item.path}
                    className="text-lg font-medium text-foreground hover:text-primary transition-colors flex items-center gap-2"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.text}
                  </Link>
                ))}

                {session.doesSessionExist && (
                  <Link
                    to={`/profile/${session.userId}`}
                    className="text-lg font-medium text-foreground hover:text-primary transition-colors"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Profile
                  </Link>
                )}

                <div className="pt-8 border-t border-border flex flex-col gap-6">
                  <AuthButtons />
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-muted-foreground">Theme</span>
                    <ThemeToggle />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
