/*
<ai_context>
  This file is updated to reference the new TypeScript-based cookie manager and consent components.
  It also ensures we initialize cookie preferences on app load.
</ai_context>
*/

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "./components/theme-provider";
import ProblemList from "./pages/ProblemList";
import Editor from "./pages/Editor";
import UserProfile from "./pages/UserProfile";
import Homepage from "./pages/HomePage";
import Dashboard from "./pages/Dashboard";
import AboutUs from "./pages/AboutUs";
import ThirdParty, { Github, Google, Facebook, Apple } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import * as reactRouterDom from "react-router-dom";
import RoleTester from "./components/role-tester";
import Leaderboard from "./pages/Leaderboard";
import Documentation from "./pages/Documentation";
import CookieConsent from "./components/cookies/cookie-consent";
import { initializeCookieConsent } from "./components/cookies/cookie-manager";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import { SessionAuth } from "./components/session-auth";
import Layout from "./components/Layout";
import { config } from './config/env';
import Achievements from "./pages/Achievements";
import { Toaster } from "./components/ui/toaster";
import LearningPaths from "./pages/LearningPaths";
import LearningPathView from "./pages/LearningPathView";
import Challenges from "./pages/Challenges";
import PricingPage from './components/pricing-page';
import { initializeAchievementTracker } from "./utils/achievement-tracker";
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import UsernameRequirement from './components/username-requirement';
import EditorTutorial from "./components/editor-tutorial";
import TermsOfService from "./pages/TermsOfService";

type Theme = "dark" | "light" | "system";

console.log('Environment:', {
  backendUrl: config.BACKEND_URL,
  frontendUrl: config.FRONTEND_URL,
  nodeEnv: config.NODE_ENV,
});

SuperTokens.init({
  appInfo: {
    appName: "SpreadsheetChallenges",
    apiDomain: config.BACKEND_URL,
    apiBasePath: "/auth",
    websiteDomain: config.FRONTEND_URL,
    websiteBasePath: "/auth",
  },
  termsOfServiceLink: "/terms",
  privacyPolicyLink: "/privacy",
  recipeList: [
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [
          Google.init(),
          Github.init(),
          // Facebook.init(),
          // Apple.init(),
        ],
      },
    }),
    EmailPassword.init(),
    Session.init(),
  ]
});

// Define a component for the homepage route that redirects to dashboard if logged in
const HomepageRoute = () => {
  const session = useSessionContext() as SessionContextType & {
    doesSessionExist: boolean;
    userId: string;
  };

  // If still loading, don't redirect yet
  if (session.loading) {
    return null;
  }
  
  // Redirect to dashboard if user is logged in
  if (session.doesSessionExist) {
    return <Navigate to="/dashboard" replace />;
  }
  
  // Otherwise, show the public homepage
  return <Homepage />;
};

const App: React.FC = () => {
  // Initialize dark/light theme based on system preference or stored preference
  const getInitialTheme = (): "dark" | "light" => {
    if (typeof window !== "undefined" && window.localStorage) {
      const storedPreference = localStorage.getItem("spreadsheet-theme");
      if (storedPreference === "dark" || storedPreference === "light") {
        return storedPreference;
      }
      const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
      if (userMedia.matches) {
        return "dark";
      }
    }
    return "light";
  };

  useEffect(() => {
    // Initialize cookie consent manager
    initializeCookieConsent();
    
    // Initialize achievement tracker when app loads
    const initAchievements = async () => {
      try {
        await initializeAchievementTracker();
      } catch (err) {
        console.error('Error initializing achievement tracker:', err);
      }
    };
    
    // Try to initialize achievements every second for up to 10 seconds
    // This ensures we initialize once the user is authenticated
    let attempts = 0;
    const checkInterval = setInterval(() => {
      // Check if session exists using the session API
      Session.doesSessionExist().then(exists => {
        if (exists) {
          console.log('User authenticated, initializing achievement tracker');
          initAchievements();
          clearInterval(checkInterval);
        }
        
        attempts++;
        if (attempts >= 10) {
          clearInterval(checkInterval);
        }
      }).catch(error => {
        console.error('Error checking session:', error);
      });
    }, 1000);
    
    return () => clearInterval(checkInterval);
  }, []);

  return (
    <SuperTokensWrapper>
      <ThemeProvider defaultTheme={getInitialTheme()} storageKey="spreadsheet-theme">
        <Router>
          <Layout>
            <Routes>
              {/* Public Routes */}
              {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])}
              <Route path="/" element={<HomepageRoute />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />

              {/* Protected Routes */}
              <Route path="/dashboard" element={<SessionAuth><UsernameRequirement><Dashboard /></UsernameRequirement></SessionAuth>} />
              <Route path="/problems" element={<SessionAuth><UsernameRequirement><ProblemList /></UsernameRequirement></SessionAuth>} />
              <Route path="/problems/:problemId" element={<SessionAuth><UsernameRequirement><Editor /></UsernameRequirement></SessionAuth>} />
              <Route path="/profile/:userId" element={<SessionAuth><UsernameRequirement><UserProfile /></UsernameRequirement></SessionAuth>} />
              <Route path="/test-roles" element={<SessionAuth><UsernameRequirement><RoleTester /></UsernameRequirement></SessionAuth>} />
              <Route path="/leaderboard" element={<SessionAuth><UsernameRequirement><Leaderboard /></UsernameRequirement></SessionAuth>} />
              <Route path="/docs" element={<SessionAuth><UsernameRequirement><Documentation /></UsernameRequirement></SessionAuth>} />
              <Route path="/learning-paths" element={<SessionAuth><UsernameRequirement><LearningPaths /></UsernameRequirement></SessionAuth>} />
              <Route path="/learning-paths/:pathId" element={<SessionAuth><UsernameRequirement><LearningPathView /></UsernameRequirement></SessionAuth>} />
              <Route path="/challenges" element={<SessionAuth><UsernameRequirement><Challenges /></UsernameRequirement></SessionAuth>} />
              <Route path="/achievements" element={<SessionAuth><UsernameRequirement><Achievements /></UsernameRequirement></SessionAuth>} />
              <Route path="/tutorial/problem" element={<SessionAuth><UsernameRequirement><EditorTutorial /></UsernameRequirement></SessionAuth>} />
            </Routes>
          </Layout>
          <Toaster />
          <CookieConsent />
        </Router>
      </ThemeProvider>
    </SuperTokensWrapper>
  );
};

export default App;