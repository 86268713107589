import React from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Clock, Trophy, Calendar } from 'lucide-react';

interface Challenge {
  challenge_id: number;
  challenge_type: 'daily' | 'weekly' | 'special';
  title: string;
  description: string;
  points: number;
  icon_url: string;
  start_time: string;
  end_time: string;
  is_active: boolean;
  problem: {
    problem_id: number;
    problem_title: string;
    level: string;
  };
  submission?: {
    completed: boolean;
    score: number;
    start_timestamp: string;
    end_timestamp: string;
  };
}

interface ChallengeCardProps {
  challenge: Challenge;
  onStart: (challengeId: number) => void;
}

export function ChallengeCard({ challenge, onStart }: ChallengeCardProps) {
  const now = new Date();
  const endTime = new Date(challenge.end_time);
  const timeRemaining = endTime.getTime() - now.getTime();
  const hoursRemaining = Math.max(0, Math.floor(timeRemaining / (1000 * 60 * 60)));
  const minutesRemaining = Math.max(0, Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)));

  const isCompleted = challenge.submission?.completed;

  return (
    <Card className={`bg-white dark:bg-gray-800 ${isCompleted ? 'border-green-500/20' : ''}`}>
      <CardContent className="p-6">
        <div className="flex items-start gap-4">
          {/* Icon */}
          <Calendar className={`h-12 w-12 shrink-0 ${isCompleted ? 'text-green-500' : 'text-primary'}`} />

          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2 mb-2">
              <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                isCompleted 
                  ? 'bg-green-500/10 text-green-500' 
                  : 'bg-primary/10 text-primary'
              } uppercase`}>
                {challenge.challenge_type}
              </span>
              <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                isCompleted 
                  ? 'bg-green-500/10 text-green-500' 
                  : 'bg-primary/10 text-primary'
              }`}>
                {challenge.problem.level}
              </span>
            </div>

            <h3 className={`text-lg font-semibold mb-1 ${isCompleted ? 'text-green-500' : ''}`}>
              {challenge.title}
            </h3>
            <p className="text-sm text-muted-foreground mb-4">
              {challenge.description}
            </p>

            <div className="flex items-center gap-4 text-sm text-muted-foreground">
              <div className="flex items-center gap-2">
                <Clock className={isCompleted ? 'text-green-500' : ''} />
                <span className={isCompleted ? 'text-green-500' : ''}>
                  {hoursRemaining}h {minutesRemaining}m remaining
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Trophy className={isCompleted ? 'text-green-500' : ''} />
                {isCompleted ? (
                  <span className="text-green-500">
                    Earned {challenge.submission?.score || 0} points
                  </span>
                ) : (
                  <span>{challenge.points} points available</span>
                )}
              </div>
            </div>
          </div>

          <Button
            onClick={() => onStart(challenge.challenge_id)}
            variant={isCompleted ? "outline" : "default"}
            className={`shrink-0 ${isCompleted ? 'border-green-500 text-green-500 hover:bg-green-500/10' : ''}`}
          >
            {isCompleted ? 'View Solution' : 'Start Challenge'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
} 