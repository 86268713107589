import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import CountUp from 'react-countup';
import { 
  Rocket, 
  BookOpen, 
  Trophy, 
  Gamepad2, 
  CheckCircle2, 
  ArrowRight,
  Star,
  Crown,
  Users,
  Target,
  Zap
} from 'lucide-react';
import GridBackground from "../components/GridBackground";
import Header from "../components/Header";

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const HomePage = () => {
  return (
    <div className="min-h-screen">
      <Header />

      <div className="container mx-auto px-4 py-8 relative">
        {/* Hero Section - Updated with new content and layout */}
        <motion.section 
          className="relative py-12 md:py-20 mt-16 md:mt-20"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <motion.div 
              className="text-left space-y-6 md:space-y-8"
              variants={fadeIn}
            >
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 dark:text-white">
                Master Spreadsheet Skills.
                <span className="block mt-2 text-[#3eb489] dark:text-[#4ade80]">Faster.</span>
              </h1>
              <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300">
                Improve your Excel & Google Sheets skills with real-world challenges. Compete on leaderboards. Learn by doing.
              </p>
              <div className="space-y-4">
                <Button 
                  asChild 
                  size="lg"
                  className="w-full sm:w-auto bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e] text-white px-8 py-6 rounded-md text-lg transition-colors duration-300"
                >
                  <Link to="/auth">Start Learning for free</Link>
                </Button>
              </div>
            </motion.div>
            <motion.div 
              className="relative hidden md:block"
              variants={fadeIn}
            >
              <div className="aspect-[16/9] rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-[#1a1f2e]/50 overflow-hidden">
                <img 
                  src="/newgameplay.png"
                  alt="SpreadsheetChallenges gameplay interface" 
                  className="w-full h-full object-contain object-middle"
                  loading="eager"
                />
              </div>
            </motion.div>
          </div>
        </motion.section>

        {/* How It Works Section */}
        <motion.section 
          className="py-16 md:py-24"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
            variants={fadeIn}
          >
            How It Works
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Target className="h-8 w-8 text-[#3eb489] dark:text-[#4ade80]" />,
                title: "Pick a Challenge",
                description: "Select a spreadsheet problem that matches your level."
              },
              {
                icon: <Zap className="h-8 w-8 text-[#3eb489] dark:text-[#4ade80]" />,
                title: "Solve in Our Online Editor",
                description: "Use your experience and solve the problems, if you get stuck, use the hints!"
              },
              {
                icon: <Trophy className="h-8 w-8 text-[#3eb489] dark:text-[#4ade80]" />,
                title: "Climb the Leaderboard",
                description: "Earn points and achievements. Compare your skills globally."
              }
            ].map((step, index) => (
              <motion.div 
                key={index}
                variants={fadeIn}
                className="text-center p-6 rounded-xl border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-[#1a1f2e]/50"
              >
                <div className="flex justify-center mb-4">
                  <div className="p-3 rounded-full bg-[#3eb489]/10 dark:bg-[#4ade80]/10">
                    {step.icon}
                  </div>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                  {step.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Why Use This Section */}
        <motion.section 
          className="py-16 md:py-24"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
            variants={fadeIn}
          >
            Why Spreadsheet Challenges?
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-6">
            {[
              "Practice real-life problems, not boring formulas",
              "Instant feedback and validation",
              "Track progress over time",
              "Compete on leaderboards",
              "Earn achievements",
              "Free to start, no credit card needed"
            ].map((feature, index) => (
              <motion.div 
                key={index}
                variants={fadeIn}
                className="flex items-start gap-3 p-4 rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-[#1a1f2e]/50"
              >
                <CheckCircle2 className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80] flex-shrink-0 mt-1" />
                <p className="text-gray-600 dark:text-gray-300">{feature}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Challenge Preview Section */}
        {/* <motion.section 
          className="py-16 md:py-24"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <div className="text-center max-w-2xl mx-auto">
            <motion.h2 
              className="text-3xl md:text-4xl font-bold mb-6 text-gray-900 dark:text-white"
              variants={fadeIn}
            >
              Try It Now
            </motion.h2>
            <motion.p 
              className="text-lg text-gray-600 dark:text-gray-300 mb-8"
              variants={fadeIn}
            >
              See how it works without signing up
            </motion.p>
            <motion.div variants={fadeIn}>
              <Button 
                asChild
                size="lg"
                className="bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e] text-white px-8 py-6 rounded-md text-lg transition-colors duration-300"
              >
                <Link to="/demo">Open Demo Challenge</Link>
              </Button>
            </motion.div>
          </div>
        </motion.section> */}

        {/* Testimonials Section */}
        <motion.section 
          className="py-16 md:py-24"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
            variants={fadeIn}
          >
            What Users Are Saying
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                quote: "This is the Duolingo for spreadsheets. I'm addicted.",
                author: "Adam S.",
                role: "Student"
              },
              {
                quote: "Way better than static Excel courses. You learn by doing.",
                author: "Oskar",
                role: "Excel veteran"
              }
            ].map((testimonial, index) => (
              <motion.div 
                key={index}
                variants={fadeIn}
                className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-[#1a1f2e]/50"
              >
                <blockquote className="text-lg text-gray-600 dark:text-gray-300 mb-4">
                  "{testimonial.quote}"
                </blockquote>
                <div className="flex items-center gap-2">
                  <div className="w-8 h-8 rounded-full bg-[#3eb489]/10 dark:bg-[#4ade80]/10 flex items-center justify-center">
                    <Users className="h-4 w-4 text-[#3eb489] dark:text-[#4ade80]" />
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900 dark:text-white">{testimonial.author}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{testimonial.role}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Pricing Section */}
        <motion.section 
          className="py-16 md:py-24"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
            variants={fadeIn}
          >
            Choose Your Plan
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {[
              {
                title: "Free Plan",
                icon: <Star className="h-6 w-6" />,
                features: [
                  "Free Problems",
                  "Leaderboard access",
                  "Basic progress tracking",
                  "Achievements"
                ],
                price: "Free",
                cta: "Get Started",
                highlighted: false
              },
              {
                title: "Pro Plan",
                icon: <Crown className="h-6 w-6" />,
                features: [
                  "All Free features",
                  "Track your progress",
                  "All historical challenges",
                  "Early access to new levels",
                  "Access to new features"
                ],
                price: "$10/month",
                cta: "Go Pro",
                highlighted: true
              }
            ].map((plan, index) => (
              <motion.div 
                key={index}
                variants={fadeIn}
                className={`p-8 rounded-xl border ${
                  plan.highlighted 
                    ? 'border-[#3eb489] dark:border-[#4ade80] bg-[#3eb489]/5 dark:bg-[#4ade80]/5' 
                    : 'border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-[#1a1f2e]/50'
                }`}
              >
                <div className="flex items-center gap-2 mb-4">
                  {plan.icon}
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white">{plan.title}</h3>
                </div>
                <p className="text-3xl font-bold mb-6 text-[#3eb489] dark:text-[#4ade80]">{plan.price}</p>
                <ul className="space-y-3 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                      <CheckCircle2 className="h-5 w-5 text-[#3eb489] dark:text-[#4ade80]" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Button 
                  asChild
                  className={`w-full ${
                    plan.highlighted 
                      ? 'bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e] text-white' 
                      : 'bg-white hover:bg-gray-50 dark:bg-[#1a1f2e] dark:hover:bg-[#2a2f3e] text-gray-900 dark:text-white border border-gray-200 dark:border-gray-800'
                  }`}
                >
                  <Link to="/auth">{plan.cta}</Link>
                </Button>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Final CTA Section */}
        <motion.section 
          className="py-16 md:py-24 text-center"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <motion.div variants={fadeIn}>
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900 dark:text-white">
              Ready to Level Up?
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
              Join thousands improving their spreadsheet skills today.
            </p>
            <Button 
              asChild
              size="lg"
              className="bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e] text-white px-8 py-6 rounded-md text-lg transition-colors duration-300"
            >
              <Link to="/auth">Get Started – It's Free</Link>
            </Button>
          </motion.div>
        </motion.section>
      </div>

      {/* Sticky CTA Button */}
      <motion.div 
        className="fixed bottom-20 right-6 z-50"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <Button 
          asChild
          size="lg"
          className="bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e] text-white px-6 py-4 rounded-full shadow-lg"
        >
          <Link to="/auth">Start Solving</Link>
        </Button>
      </motion.div>
    </div>
  );
};

export default HomePage; 