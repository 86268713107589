import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Card } from "../components/ui/card";
import Session from 'supertokens-auth-react/recipe/session';
import { useToast } from "../components/ui/use-toast";
import { useSubscription } from '../hooks/use-subscription';
import { Loader2 } from 'lucide-react';

const PricingPage: React.FC = () => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubscribed, loading: subscriptionLoading, error: subscriptionError } = useSubscription();

  const handleSubscribe = async () => {
    try {
      setIsLoading(true);
      console.log("Starting checkout session creation...");
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      console.log("Response status:", response.status);
      if (!response.ok) {
        const errorData = await response.text();
        console.error("Error response:", errorData);
        throw new Error(`Failed to create checkout session: ${errorData}`);
      }

      const data = await response.json();
      console.log("Checkout session created:", data);
      window.location.href = data.url;
    } catch (error) {
      console.error('Detailed error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to start checkout process. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to create portal session');
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: "Error",
        description: "Failed to open subscription management. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Show loading state while checking subscription
  if (subscriptionLoading) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <Loader2 className="h-8 w-8 animate-spin mx-auto" />
        <p className="mt-4">Loading subscription status...</p>
      </div>
    );
  }

  // Show error state if subscription check failed
  if (subscriptionError) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-6">Error</h1>
        <p className="mb-4 text-red-500">{subscriptionError}</p>
        <Button onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    );
  }

  // Show login prompt if not authenticated
  if (!Session.doesSessionExist()) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-6">Please Log In</h1>
        <p className="mb-4">You need to be logged in to manage subscriptions.</p>
        <Button onClick={() => window.location.href = '/auth'}>
          Log In
        </Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold text-center mb-12">Choose Your Plan</h1>
      
      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        {/* Free Plan */}
        <Card className="p-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">Free Plan</h2>
          <p className="text-3xl font-bold mb-6">$0<span className="text-lg font-normal">/month</span></p>
          <ul className="space-y-3 mb-6">
            <li className="text-green-600">✓ Access to a few problems</li>
            <li className="text-red-600">✗ Challenges</li>
            <li className="text-red-600">✗ Learning Paths</li>
            <li className="text-red-600">✗ Achievement system</li>
          </ul>
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => window.location.href = '/problems'}
          >
            Get Started
          </Button>
        </Card>

        {/* Pro Plan */}
        <Card className="p-6 text-center relative border-primary/50 shadow-lg">
          <div className="absolute -top-3 right-4 bg-primary/10 border border-primary/20 backdrop-blur-sm text-primary px-4 py-1.5 text-sm font-medium rounded-full">
            Most Popular
          </div>
          <h2 className="text-2xl font-semibold mb-4">Pro Plan</h2>
          <p className="text-3xl font-bold mb-6">$10<span className="text-lg font-normal">/month</span></p>
          <ul className="space-y-3 mb-6">
            <li className="text-green-600">✓ All Free Plan features</li>
            <li className="text-green-600">✓ Access to all challenges</li>
            <li className="text-green-600">✓ Learning paths</li>
            <li className="text-green-600">✓ Achievement system</li>
          </ul>
          {isSubscribed ? (
            <Button 
              className="w-full"
              onClick={handleManageSubscription}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Manage Subscription"}
            </Button>
          ) : (
            <Button 
              className="w-full"
              onClick={handleSubscribe}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Upgrade to Pro"}
            </Button>
          )}
        </Card>
      </div>

    </div>
  );
};

export default PricingPage; 