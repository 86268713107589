import React, { useEffect, useState } from 'react';
import { Trophy, X } from 'lucide-react';
import ReactDOM from 'react-dom';

interface Achievement {
  // Support different field naming conventions from different API responses
  achievement_id?: number;
  id?: number;
  name?: string;
  title?: string;
  description?: string;
  icon_url?: string;
  badge_color?: string;
  color?: string;
  points?: number;
  score?: number;
  is_new?: boolean;
}

interface AchievementToastProps {
  achievement: Achievement;
  onClose?: () => void;
}

export function AchievementToast({ achievement, onClose }: AchievementToastProps) {
  // Normalize achievement data to handle different formats
  const name = achievement.name || achievement.title || 'Achievement Unlocked';
  const description = achievement.description || '';
  const iconUrl = achievement.icon_url || '';
  const badgeColor = achievement.badge_color || achievement.color || '#3eb489';
  const points = achievement.points || achievement.score || 0;
  
  console.log('Rendering achievement toast for:', name);

  // Get the current theme
  const isDarkMode = document.documentElement.classList.contains('dark');
  const bgColor = isDarkMode ? '#1f2937' : '#ffffff'; // dark gray or white
  const textColor = isDarkMode ? '#ffffff' : '#111827';
  const mutedTextColor = isDarkMode ? '#d1d5db' : '#374151';

  return (
    <div 
      className="animate-bounce-once"
      style={{
        position: 'relative',
        backgroundColor: bgColor,
        color: textColor,
        border: '2px solid #facc15',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 12px rgba(250, 204, 21, 0.2)',
        padding: '16px',
        width: '360px',
        maxWidth: '90vw',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {iconUrl ? (
          <img 
            src={iconUrl} 
            alt={name}
            style={{ 
              width: '48px', 
              height: '48px', 
              padding: '4px', 
              backgroundColor: 'rgba(250, 204, 21, 0.2)',
              borderRadius: '0.5rem'
            }}
          />
        ) : (
          <div 
            style={{ 
              width: '48px', 
              height: '48px', 
              borderRadius: '0.5rem',
              backgroundColor: badgeColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Trophy size={28} color="#ffffff" />
          </div>
        )}
        <div>
          <h3 style={{ 
            fontSize: '1.25rem', 
            fontWeight: 'bold', 
            color: '#ca8a04',
            margin: 0
          }}>
            Achievement Unlocked!
          </h3>
          <p style={{ 
            fontWeight: '600', 
            color: textColor,
            margin: 0
          }}>
            {name}
          </p>
        </div>
      </div>
      
      <p style={{ 
        fontSize: '0.875rem', 
        color: mutedTextColor,
        paddingLeft: '64px',
        margin: 0
      }}>
        {description}
      </p>
      
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '8px',
        paddingLeft: '64px'
      }}>
        <Trophy size={16} color="#ca8a04" />
        <span style={{ 
          fontSize: '0.875rem', 
          fontWeight: '500', 
          color: '#ca8a04' 
        }}>
          +{points} points
        </span>
      </div>
      
      {onClose && (
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: mutedTextColor,
            padding: '4px',
            borderRadius: '4px'
          }}
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
}

// Custom Toast Container Component that overlays the entire screen
function AchievementToastContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    >
      <div style={{ pointerEvents: 'auto' }}>
        {children}
      </div>
    </div>
  );
}

export function showAchievementToast(achievement: Achievement) {
  console.log('Showing achievement toast with data:', achievement);
  
  // Create a container for our toast if it doesn't exist
  let toastContainer = document.getElementById('achievement-toast-container');
  if (!toastContainer) {
    toastContainer = document.createElement('div');
    toastContainer.id = 'achievement-toast-container';
    document.body.appendChild(toastContainer);
  }
  
  // Create a unique ID for this toast
  const toastId = `achievement-toast-${Date.now()}`;

  // Create a cleanup function to remove the toast after duration
  const removeToast = () => {
    const toastElement = document.getElementById(toastId);
    if (toastElement) {
      // Add explosion animation
      const contentElement = toastElement.querySelector('.animate-bounce-once');
      if (contentElement) {
        contentElement.classList.remove('animate-bounce-once');
        contentElement.classList.add('animate-explode');
        
        // Add some particle effects for extra flair
        const createParticles = () => {
          // Create 10 particle elements
          for (let i = 0; i < 12; i++) {
            const particle = document.createElement('div');
            const size = Math.random() * 10 + 5;
            const angle = Math.random() * 360;
            const distance = Math.random() * 60 + 30;
            const duration = Math.random() * 0.5 + 0.5;
            const hue = Math.random() * 60 + 30; // golden yellow hues
            
            particle.style.position = 'absolute';
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;
            particle.style.borderRadius = '50%';
            particle.style.backgroundColor = `hsl(${hue}, 90%, 60%)`;
            particle.style.top = '50%';
            particle.style.left = '50%';
            particle.style.transform = 'translate(-50%, -50%)';
            particle.style.opacity = '1';
            particle.style.zIndex = '-1';
            particle.style.transition = `all ${duration}s ease-out`;
            
            contentElement.appendChild(particle);
            
            // Trigger animation in the next frame
            setTimeout(() => {
              const x = Math.cos(angle * (Math.PI / 180)) * distance;
              const y = Math.sin(angle * (Math.PI / 180)) * distance;
              particle.style.transform = `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`;
              particle.style.opacity = '0';
            }, 10);
          }
        };
        
        createParticles();
        
        // Remove after animation completes
        setTimeout(() => {
          if (toastElement.parentNode) {
            ReactDOM.unmountComponentAtNode(toastElement);
            toastElement.remove();
          }
        }, 800);
      } else {
        if (toastElement.parentNode) {
          ReactDOM.unmountComponentAtNode(toastElement);
          toastElement.remove();
        }
      }
    }
  };
  
  // Create a div for this specific toast
  const toastElement = document.createElement('div');
  toastElement.id = toastId;
  toastContainer.appendChild(toastElement);
  
  // Render the toast in the container
  ReactDOM.render(
    <AchievementToastContainer>
      <AchievementToast achievement={achievement} onClose={removeToast} />
    </AchievementToastContainer>,
    toastElement
  );
  
  // Set a timeout to remove the toast after a shorter duration (2.5 seconds)
  setTimeout(removeToast, 3500);
} 