import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { Loading } from '../components/ui/loading';
import { CustomAlert } from '../components/ui/custom-alert';
import { Card, CardContent } from '../components/ui/card';
import { Progress } from '../components/ui/progress';
import { Button } from '../components/ui/button';
import { BookOpen, ChevronRight, CheckCircle, Lock, ArrowLeft } from 'lucide-react';
import { getApiUrl } from '../config/env';
import { useSubscription } from '../hooks/use-subscription';
import PremiumModal from '../components/premium-modal';
import { handleNewAchievements } from '../utils/achievement-handler';

interface LearningPathStep {
  id: number;
  problem_id: number;
  step_order: number;
  required_score: number;
  problem: {
    title: string;
    description: string;
    level: string;
  };
}

interface LearningPath {
  path_id: number;
  title: string;
  description: string;
  icon_url: string;
  progress: {
    completed_steps: number;
    current_step: number;
    completed_at: string | null;
    started_at: string | null;
  };
  total_steps: number;
  steps: LearningPathStep[];
}

export default function LearningPathView() {
  const [path, setPath] = useState<LearningPath | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const { pathId } = useParams();
  const navigate = useNavigate();
  const { isSubscribed, loading: subscriptionLoading } = useSubscription();

  useEffect(() => {
    // If subscription status is loaded and user is not premium, show the premium modal
    if (!subscriptionLoading && !isSubscribed) {
      setShowPremiumModal(true);
      setLoading(false);
      return;
    }

    // Only fetch path details if user is premium
    if (!subscriptionLoading && isSubscribed) {
      fetchPathDetails();
    }
  }, [pathId, navigate, subscriptionLoading, isSubscribed]);

  const fetchPathDetails = async () => {
    try {
      const response = await fetch(getApiUrl(`/api/learning-paths/${pathId}`), {
        credentials: 'include'
      });

      if (response.status === 401) {
        navigate('/auth');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch learning path details');
      }

      const data = await response.json();
      setPath(data.path);
      
      // Check for new achievements in the response
      handleNewAchievements(data);
    } catch (err) {
      setError('Failed to load learning path details. Please try again later.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleStartStep = (problemId: number) => {
    navigate(`/problems/${problemId}`);
  };

  if (loading || subscriptionLoading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <Loading />
        </div>
      </div>
    );
  }

  if (showPremiumModal) {
    return (
      <div className="min-h-screen">
        <Header />
        <PremiumModal
          open={showPremiumModal}
          onClose={() => navigate('/learning-paths')}
          onUpgrade={() => navigate('/pricing')}
        />
      </div>
    );
  }

  if (!path) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="container mx-auto px-4 py-8">
          <CustomAlert variant="error" className="max-w-2xl mx-auto">
            {error || 'Learning path not found'}
          </CustomAlert>
        </div>
      </div>
    );
  }

  const progress = Math.round((path.progress.completed_steps / path.total_steps) * 100);

  return (
    <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Back button */}
          <Button
            variant="ghost"
            className="mb-6"
            onClick={() => navigate('/learning-paths')}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Learning Paths
          </Button>

          {/* Path Header */}
          <Card className="mb-8">
            <CardContent className="p-6">
              <div className="flex items-start gap-6">
                {/* Icon */}
                <div className="shrink-0">
                  {path.icon_url ? (
                    <img 
                      src={path.icon_url} 
                      alt={path.title}
                      className="w-16 h-16 rounded-lg"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-lg bg-primary/10 flex items-center justify-center">
                      <BookOpen className="h-8 w-8 text-primary" />
                    </div>
                  )}
                </div>

                {/* Content */}
                <div className="flex-1">
                  <h1 className="text-2xl font-bold mb-2">{path.title}</h1>
                  <p className="text-muted-foreground mb-4">
                    {path.description}
                  </p>
                  
                  {/* Progress Section */}
                  <div className="space-y-2">
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-muted-foreground">
                        {path.progress.completed_steps} of {path.total_steps} steps completed
                      </span>
                      <span className="font-medium">{progress}%</span>
                    </div>
                    <Progress value={progress} className="h-2" />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Steps List */}
          <div className="space-y-4">
            {path.steps?.map((step, index) => {
              const isCompleted = index < path.progress.current_step - 1;
              const isCurrent = index === path.progress.current_step - 1;
              const isLocked = index > path.progress.current_step - 1;

              return (
                <Card 
                  key={step.id}
                  className={`transition-all duration-200 ${
                    isLocked ? 'opacity-50' : ''
                  }`}
                >
                  <CardContent className="p-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10">
                          {isCompleted || path.progress.completed_at ? (
                            <CheckCircle className="w-5 h-5 text-green-500" />
                          ) : isLocked ? (
                            <Lock className="w-5 h-5 text-muted-foreground" />
                          ) : (
                            <span className="text-primary font-medium">{index + 1}</span>
                          )}
                        </div>
                        <div>
                          <h3 className="font-semibold">
                            {step.problem.title}
                          </h3>
                          <p className="text-sm text-muted-foreground">
                            {step.problem.description}
                          </p>
                          <div className="flex items-center gap-2 mt-1">
                            <span className="text-xs px-2 py-1 rounded-full bg-primary/10 text-primary">
                              {step.problem.level}
                            </span>
                            {step.required_score > 0 && (
                              <span className="text-xs px-2 py-1 rounded-full bg-primary/10 text-primary">
                                Required Score: {step.required_score}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      
                      <Button
                        onClick={() => handleStartStep(step.problem_id)}
                        disabled={isLocked && !path.progress.completed_at}
                        variant={(isCompleted || path.progress.completed_at) ? "outline" : "default"}
                      >
                        {(isCompleted || path.progress.completed_at) ? 'Review' : isCurrent ? 'Continue' : 'Start'}
                        <ChevronRight className="w-4 h-4 ml-2" />
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
} 