import React from "react";
import { Lock, BarChart3, Settings, Globe, Shield } from "lucide-react";
import Header from "../components/Header";
import GridBackground from "../components/GridBackground";
import { Card } from "../components/ui/card";

const CookiePolicy = () => {
  const cookieTypes = [
    {
      icon: <Lock className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Essential Cookies",
      description: "Required for basic website functionality",
    },
    {
      icon: <BarChart3 className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Analytics Cookies",
      description: "Help us understand how you use our site (we use cookie-free analytics where possible)",
    },
    {
      icon: <Settings className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Preference Cookies",
      description: "Remember your settings and preferences",
    },
  ];

  const usageSections = [
    {
      title: "Authentication",
      description: "We use cookies to identify you when you visit our website and as you navigate our website. Our authentication service (SuperTokens) uses secure, HttpOnly cookies to maintain your login state and protect your account.",
    },
    {
      title: "Status",
      description: "We use cookies to help us determine if you are logged into our website.",
    },
    {
      title: "Personalization",
      description: "We use cookies to store information about your preferences and to personalize the website for you.",
    },
    {
      title: "Security",
      description: "We use cookies as an element of the security measures to protect user accounts and our website in general.",
    },
  ];

  const browsers = [
    {
      icon: <Globe className="h-6 w-6" />,
      name: "Chrome",
      link: "https://support.google.com/chrome/answer/95647",
    },
    {
      icon: <Globe className="h-6 w-6" />,
      name: "Firefox",
      link: "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer",
    },
    {
      icon: <Globe className="h-6 w-6" />,
      name: "Safari",
      link: "https://support.apple.com/guide/safari/manage-cookies-sfri11471",
    },
  ];

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="min-h-[calc(100vh-64px)] flex items-center justify-center py-16">
          <div className="w-full max-w-4xl mx-auto px-4">
            <Card className="overflow-hidden border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 backdrop-blur-xl">
              <div className="p-8">
                {/* Header */}
                <div className="text-center mb-12">
                  <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>
                  <p className="text-gray-600 dark:text-gray-400">
                    Last updated: {new Date().toLocaleDateString()}
                  </p>
                </div>

                {/* Overview */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-4">Overview</h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    This Cookie Policy explains how Spreadsheet Challenges uses cookies and similar tracking
                    technologies to recognize you when you visit our website. It explains what these technologies
                    are and why we use them, as well as your rights to control our use of them.
                  </p>
                </div>

                {/* Cookie Types */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Types of Cookies</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {cookieTypes.map((type, index) => (
                      <div
                        key={index}
                        className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30"
                      >
                        <div className="mb-4">{type.icon}</div>
                        <h3 className="text-lg font-semibold mb-2">{type.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{type.description}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Usage */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">How We Use Cookies</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {usageSections.map((section, index) => (
                      <div
                        key={index}
                        className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30"
                      >
                        <h3 className="text-lg font-semibold mb-2">{section.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{section.description}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Third Party */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Third-Party Services</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30">
                    <p className="text-gray-600 dark:text-gray-400 mb-6">
                      We use some carefully selected third-party services that may set cookies on your device:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="p-4 rounded-lg bg-white/30 dark:bg-gray-900/30">
                        <h3 className="font-semibold mb-2">GoatCounter</h3>
                        <p className="text-gray-600 dark:text-gray-400">Privacy-focused website analytics that does not use cookies by default</p>
                      </div>
                      <div className="p-4 rounded-lg bg-white/30 dark:bg-gray-900/30">
                        <h3 className="font-semibold mb-2">Supertokens</h3>
                        <p className="text-gray-600 dark:text-gray-400">Authentication services that use secure, HttpOnly cookies for session management and user authentication</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Analytics Section */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Analytics & Tracking</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30">
                    <p className="text-gray-600 dark:text-gray-400 mb-4">
                      We use GoatCounter for our website analytics, which is designed to be privacy-friendly:
                    </p>
                    <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2 mb-4">
                      <li><strong>Cookie-Free by Default:</strong> GoatCounter does not use cookies or localStorage by default for tracking visitors</li>
                      <li><strong>No Cross-Site Tracking:</strong> GoatCounter doesn't track users across different websites</li>
                      <li><strong>Minimal Data Collection:</strong> Only collects anonymous, aggregated data about page visits, referrers, browsers, screen sizes, and countries</li>
                      <li><strong>GDPR Compliant:</strong> Designed with privacy regulations in mind</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                      This approach allows us to understand how our website is used while respecting your privacy.
                    </p>
                  </div>
                </div>

                {/* Browser Management */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Managing Cookies</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {browsers.map((browser, index) => (
                      <a
                        key={index}
                        href={browser.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30 hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          {browser.icon}
                          <span className="font-semibold">{browser.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>

                {/* Contact */}
                <div>
                  <h2 className="text-2xl font-semibold mb-6">Questions?</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30 text-center">
                    <p className="text-gray-600 dark:text-gray-400 mb-4">
                      If you have any questions about our use of cookies, please contact us at:
                    </p>
                    <a
                      href="mailto:info@spreadsheetchallenges.com"
                      className="text-[#3eb489] dark:text-[#4ade80] hover:underline"
                    >
                      info@spreadsheetchallenges.com
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
  );
};

export default CookiePolicy; 