import React, { useState } from 'react';
import { ChallengeCard } from './challenge-card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Trophy, Search, Filter } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

interface Challenge {
  challenge_id: number;
  challenge_type: 'daily' | 'weekly' | 'special';
  title: string;
  description: string;
  points: number;
  icon_url: string;
  start_time: string;
  end_time: string;
  is_active: boolean;
  problem: {
    problem_id: number;
    problem_title: string;
    level: string;
  };
  submission?: {
    completed: boolean;
    score: number;
    start_timestamp: string;
    end_timestamp: string;
  };
}

interface ChallengesListProps {
  challenges: Challenge[];
  onStartChallenge: (challengeId: number) => void;
  className?: string;
}

export function ChallengesList({ challenges, onStartChallenge, className }: ChallengesListProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState<'all' | 'daily' | 'weekly' | 'special'>('all');
  const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'completed'>('all');

  // Calculate points earned from completed challenges
  const pointsEarned = challenges.reduce((sum, challenge) => 
    sum + (challenge.submission?.completed ? (challenge.submission.score || 0) : 0), 0
  );

  // Calculate available points from active, uncompleted challenges
  const availablePoints = challenges.reduce((sum, challenge) => 
    sum + (!challenge.submission?.completed && challenge.is_active ? challenge.points : 0), 0
  );

  const filteredChallenges = challenges.filter(challenge => {
    const matchesSearch = 
      challenge.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      challenge.description.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesType = 
      filterType === 'all' || challenge.challenge_type === filterType;

    const matchesStatus = 
      filterStatus === 'all' ||
      (filterStatus === 'completed' && challenge.submission?.completed) ||
      (filterStatus === 'active' && !challenge.submission?.completed);

    return matchesSearch && matchesType && matchesStatus;
  });

  return (
    <div className={className}>
      {/* Header with points summary */}
      <div className="mb-8 p-6 rounded-lg bg-primary/5 border">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <Trophy className="w-6 h-6 text-primary" />
          Challenges
        </h2>
        <div className="flex items-center gap-6 text-muted-foreground">
          <p>Points earned: {pointsEarned}</p>
          <p>Available points: {availablePoints}</p>
        </div>
      </div>

      {/* Search and filter controls */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-muted-foreground" />
          <Input
            placeholder="Search challenges..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-9"
          />
        </div>
        <Select
          value={filterType}
          onValueChange={(value: 'all' | 'daily' | 'weekly' | 'special') => setFilterType(value)}
        >
          <SelectTrigger className="w-full sm:w-[180px]">
            <Filter className="w-4 h-4 mr-2" />
            <SelectValue placeholder="Filter by type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Types</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="special">Special</SelectItem>
          </SelectContent>
        </Select>
        <Select
          value={filterStatus}
          onValueChange={(value: 'all' | 'active' | 'completed') => setFilterStatus(value)}
        >
          <SelectTrigger className="w-full sm:w-[180px]">
            <Filter className="w-4 h-4 mr-2" />
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Status</SelectItem>
            <SelectItem value="active">Active</SelectItem>
            <SelectItem value="completed">Completed</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Challenge cards */}
      <div className="space-y-4">
        {filteredChallenges.map((challenge) => (
          <ChallengeCard
            key={challenge.challenge_id}
            challenge={challenge}
            onStart={onStartChallenge}
          />
        ))}
      </div>

      {/* Empty state */}
      {filteredChallenges.length === 0 && (
        <div className="text-center py-12">
          <Trophy className="w-12 h-12 text-muted-foreground mx-auto mb-4" />
          <h3 className="text-lg font-semibold mb-2">No challenges found</h3>
          <p className="text-muted-foreground">
            {searchQuery
              ? "No challenges match your search criteria"
              : "Check back later for new challenges!"}
          </p>
        </div>
      )}
    </div>
  );
} 