import { useEffect, useState } from 'react';
import Session from 'supertokens-auth-react/recipe/session';

interface SubscriptionStatus {
  is_subscribed: boolean;
  subscription_id?: string;
  updated_at?: number;
}

export function useSubscription() {
  const [status, setStatus] = useState<SubscriptionStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkSubscription = async () => {
      if (await Session.doesSessionExist()) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/subscription-status`, {
            credentials: 'include'
          });
          if (!response.ok) {
            throw new Error('Failed to fetch subscription status');
          }
          const data = await response.json();
          setStatus(data);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Unknown error');
        } finally {
          setLoading(false);
        }
      } else {
        setStatus({ is_subscribed: false });
        setLoading(false);
      }
    };

    checkSubscription();
  }, []);

  return {
    isSubscribed: status?.is_subscribed ?? false,
    subscriptionId: status?.subscription_id,
    loading,
    error,
    refresh: () => {
      setLoading(true);
      setError(null);
      // Re-run the effect
      const checkSubscription = async () => {
        if (await Session.doesSessionExist()) {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/subscription-status`, {
              credentials: 'include'
            });
            if (!response.ok) {
              throw new Error('Failed to fetch subscription status');
            }
            const data = await response.json();
            setStatus(data);
          } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
          } finally {
            setLoading(false);
          }
        } else {
          setStatus({ is_subscribed: false });
          setLoading(false);
        }
      };
      checkSubscription();
    }
  };
} 