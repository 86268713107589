import React from "react";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { Sun, Moon, Menu } from "lucide-react";
import { useTheme } from "./theme-provider";
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import AuthButtons from "./auth-buttons";
import Header from "./Header";
import Footer from "./footer";
import GridBackground from "./GridBackground";
import FloatingFeedbackButton from "./floating-feedback-button";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { theme, setTheme } = useTheme();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const session = useSessionContext() as SessionContextType & {
    doesSessionExist: boolean;
    userId: string;
  };

  const menuItems = [
    { text: 'Problems', path: '/problems' },
    { text: 'Documentation', path: '/docs' },
    { text: 'Leaderboard', path: '/leaderboard' },
    { text: 'Pricing', path: '/pricing' },
    { text: 'About Us', path: '/aboutus' },
  ];

  return (
    <GridBackground>
      <div className="flex flex-col min-h-screen bg-background/50 text-foreground">
        {/* Header */}
        <Header />

        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-[101] md:hidden">
            <div className="fixed inset-0 bg-black/20 backdrop-blur-sm" onClick={() => setMobileMenuOpen(false)} />
            <div className="fixed right-0 top-0 h-full w-3/4 max-w-xs bg-white dark:bg-gray-900 p-6 shadow-lg">
              <div className="flex flex-col space-y-6">
                {menuItems.map((item) => (
                  <Link
                    key={item.text}
                    to={item.path}
                    className="text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-[#3eb489] dark:hover:text-[#4ade80] transition-colors"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.text}
                  </Link>
                ))}
                {session.doesSessionExist && (
                  <Link
                    to={`/profile/${session.userId}`}
                    className="text-sm font-medium text-[#3eb489] dark:text-[#4ade80] hover:text-[#2d8b6f] dark:hover:text-[#3ade70] transition-colors"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Profile
                  </Link>
                )}
                <div className="pt-4">
                  <AuthButtons />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Spacer div to account for fixed header */}
        <div className="h-16" />

        {/* Main content */}
        <main className="flex-grow relative">
          {children}
        </main>

        {/* Footer */}
        <Footer />
        
        {/* Floating Feedback Button */}
        <FloatingFeedbackButton />
      </div>
    </GridBackground>
  );
};

export default Layout; 