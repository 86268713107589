import React from 'react';
import { Card, CardContent } from "../components/ui/card";

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <Card>
        <CardContent className="space-y-6">
          <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
          
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="text-muted-foreground">
              By accessing, registering, or using this website ("the Platform"), you agree to abide by these Terms of Service. 
              If you do not agree with these terms, please refrain from using the Platform.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Use of Personal Information</h2>
            <p className="text-muted-foreground">
              Upon signing up, you consent to receiving promotional and informational communications from us via email. 
              We respect your privacy and will never resell or distribute your personal information or email address to third parties.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property</h2>
            <p className="text-muted-foreground">
              All content, challenges, problems, formulas, and materials available on the Platform are proprietary to us or 
              licensed exclusively for use on this Platform. By using the Platform, you acknowledge that you have no rights 
              to copy, redistribute, sell, or commercially exploit any of the content provided.
            </p>
            <p className="text-muted-foreground mt-4">
              Unauthorized reproduction or distribution of our content is strictly prohibited and may result in legal action.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Modifications to Terms</h2>
            <p className="text-muted-foreground">
              We reserve the right to amend these Terms of Service at any time. Changes will become effective immediately 
              upon posting on this Platform. Continued use of the Platform following such modifications constitutes 
              acceptance of the revised terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-muted-foreground">
              If you have any questions regarding these Terms of Service, please contact us through the provided channels 
              on our Platform.
            </p>
          </section>
        </CardContent>
      </Card>
    </div>
  );
};

export default TermsOfService; 