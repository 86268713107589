import React from 'react';

interface MiniSheetProps {
  cellRanges: string[] | string;
  headers: string[] | string;
  numberOfLines: number;
  exampleData: any[][] | string;
}

const MiniSheet: React.FC<MiniSheetProps> = ({
  cellRanges,
  headers,
  numberOfLines,
  exampleData
}) => {
  // Ensure cellRanges is always an array
  const cellRangesArray = Array.isArray(cellRanges) 
    ? cellRanges 
    : (typeof cellRanges === 'string' ? JSON.parse(cellRanges) : []);
    
  // Ensure headers is always an array
  const headersArray = Array.isArray(headers) 
    ? headers 
    : (typeof headers === 'string' ? JSON.parse(headers) : []);
    
  // Ensure exampleData is always an array of arrays
  const exampleDataArray = Array.isArray(exampleData) 
    ? exampleData 
    : (typeof exampleData === 'string' ? JSON.parse(exampleData) : []);
  
  // Handle case when data is not available
  if (!exampleDataArray || exampleDataArray.length === 0) {
    return null;
  }

  return (
    <div className="mt-4 mb-6">
      <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
        Data Preview
      </h3>
      <div className="overflow-x-auto rounded-md border border-gray-200 dark:border-gray-700">
        <table className="min-w-full border-collapse border border-gray-200 dark:border-gray-700">
          {headersArray && headersArray.length > 0 && (
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                {headersArray.map((header: string, index: number) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider border border-gray-200 dark:border-gray-700"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
            {exampleDataArray.slice(0, numberOfLines).map((row: any[], rowIndex: number) => (
              <tr key={rowIndex}>
                {row.map((cell: any, cellIndex: number) => (
                  <td
                    key={cellIndex}
                    className="px-4 py-2 text-sm text-gray-900 dark:text-gray-100 border border-gray-200 dark:border-gray-700"
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MiniSheet;
