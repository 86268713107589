import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trophy, Calendar, Clock, BarChart2, Trophy as TrophyIcon, Flame, Check, Zap } from 'lucide-react';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Progress } from '../components/ui/progress';
import Header from '../components/Header';
import { getApiUrl } from '../config/env';
import { Loading } from '../components/ui/loading';
import { handleNewAchievements } from '../utils/achievement-handler';
import { checkForNewAchievements } from '../utils/achievement-tracker';
import { FirstChallengeOverlay } from '../components/first-challenge-overlay';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';

// Global API cache to prevent duplicate calls
interface ApiCacheItem {
  response: Response;
  timestamp: number;
  data: any;
}

const API_CACHE: Record<string, Map<string, ApiCacheItem>> = {
  profile: new Map<string, ApiCacheItem>(),
  challenges: new Map<string, ApiCacheItem>(),
  streaks: new Map<string, ApiCacheItem>(),
  firstChallengeStatus: new Map<string, ApiCacheItem>(),
  achievements: new Map<string, ApiCacheItem>()
};

interface Challenge {
  challenge_id: number;
  challenge_type: 'daily' | 'weekly' | 'special';
  title: string;
  description: string;
  points: number;
  icon_url: string;
  start_time: string;
  end_time: string;
  is_active: boolean;
  problem: {
    problem_id: number;
    problem_title: string;
    level: string;
  };
  submission?: {
    completed: boolean;
    score: number;
    start_timestamp: string;
    end_timestamp: string;
  };
}

interface StreakInfo {
  current_streak: number;
  longest_streak: number;
  last_activity_date: string | null;
  recent_activity: string[];
  next_reset: string;
  needs_activity_today: boolean;
}

interface UserProfile {
  id: string;
  username: string;
  email: string;
  role: string;
  total_score: number;
  score_breakdown?: {
    problems: number;
    challenges: number;
    achievements: number;
  };
  solved_problems: Array<{
    id: number;
    title: string;
    points: number;
    completed_at: string;
    hints_used: number;
  }>;
  achievements: Array<any>;
  avatar_url: string;
}

const Dashboard = () => {
  // State variables
  const [dailyChallenge, setDailyChallenge] = useState<Challenge | null>(null);
  const [streakInfo, setStreakInfo] = useState<StreakInfo | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showFirstChallengeOverlay, setShowFirstChallengeOverlay] = useState(false);
  
  const navigate = useNavigate();
  const session = useSessionContext() as SessionContextType;
  
  // Enhanced fetch function with caching
  const cachedFetchWithAuth = useCallback(async (cacheKey: string, url: string, userId: string, options: RequestInit = {}) => {
    // Check cache first
    if (API_CACHE[cacheKey].has(userId)) {
      const cached = API_CACHE[cacheKey].get(userId)!;
      // Cache is still fresh (5 minute TTL)
      if (Date.now() - cached.timestamp < 5 * 60 * 1000) {
        console.log(`Using cached ${cacheKey} data for user ${userId}`);
        return { 
          ok: true,
          json: () => Promise.resolve(cached.data)
        } as Response;
      }
      // If stale, remove from cache
      API_CACHE[cacheKey].delete(userId);
    }
    
    // If not in cache or stale, make the API call
    try {
      console.log(`Fetching fresh ${cacheKey} data for user ${userId}`);
      const response = await fetch(getApiUrl(url), {
        ...options,
        credentials: 'include'
      });
      
      // Only cache successful responses
      if (response.ok) {
        const clonedResponse = response.clone();
        const data = await clonedResponse.json();
        
        // Store in cache
        API_CACHE[cacheKey].set(userId, {
          response: response.clone(),
          timestamp: Date.now(),
          data
        });
        
        return { 
          ok: true,
          json: () => Promise.resolve(data)
        } as Response;
      }
      
      return response;
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      throw error;
    }
  }, []);

  // Handle debug URL parameters only once on component mount
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetOverlay = urlParams.get('reset_overlay');
    
    if (resetOverlay === 'true') {
      // Remove the parameter from URL
      urlParams.delete('reset_overlay');
      const newUrl = window.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : '');
      window.history.replaceState({}, '', newUrl);
      
      // Show the overlay without checking backend
      setShowFirstChallengeOverlay(true);
    }
  }, []);
  
  // Single data fetching effect to ensure one API call per endpoint
  useEffect(() => {
    // Skip if session is loading
    if (session.loading) return;
    
    // Redirect if not authenticated
    if (!session.doesSessionExist || !session.userId) {
      navigate('/auth');
      return;
    }

    const userId = session.userId;
    setLoading(true);

    // Consolidated data fetching function to load all dashboard data
    const fetchAllDashboardData = async () => {
      try {
        // STEP 1: Fetch everything with our cached fetch - each endpoint will only be called once
        const [firstChallengeResponse, challengesResponse, streakResponse, profileResponse] = await Promise.all([
          cachedFetchWithAuth(
            'firstChallengeStatus', 
            `/api/users/${userId}/first-challenge-status`, 
            userId, 
            {}
          ),
          cachedFetchWithAuth(
            'challenges', 
            '/api/challenges/current', 
            userId, 
            {}
          ),
          cachedFetchWithAuth(
            'streaks', 
            '/api/streaks/info', 
            userId, 
            {}
          ),
          cachedFetchWithAuth(
            'profile', 
            `/api/users/${userId}/profile`, 
            userId, 
            {}
          )
        ]);

        // STEP 2: Update component state with fetched data

        // Process first challenge status
        if (firstChallengeResponse.ok) {
          const { has_seen_first_challenge } = await firstChallengeResponse.json();
          if (!has_seen_first_challenge) {
            setShowFirstChallengeOverlay(true);
          }
        }

        // Process challenges data
        if (challengesResponse.ok) {
          const { challenges: challengesData } = await challengesResponse.json();
          const dailyChallenge = challengesData && challengesData.length > 0 ? challengesData[0] : null;
          setDailyChallenge(dailyChallenge || null);
        }
        
        // Process streak data
        if (streakResponse.ok) {
          const streakData = await streakResponse.json();
          setStreakInfo(streakData);
        }
        
        // Process profile data
        if (profileResponse.ok) {
          const profileData = await profileResponse.json();
          setUserProfile(profileData);
          
          // Process any new achievements from profile data if needed
          if (profileData.achievements) {
            handleNewAchievements(profileData.achievements);
          }
        }
        
        // Check for achievements - this will only run once per session
        if (!API_CACHE.achievements.has(userId)) {
          checkForNewAchievements();
          API_CACHE.achievements.set(userId, {
            response: new Response(),
            timestamp: Date.now(),
            data: {}
          });
        }
        
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load your dashboard. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAllDashboardData();
  }, [navigate, session, cachedFetchWithAuth]);
  
  // Handle dismissing the first challenge overlay - only called when user clicks dismiss
  const handleDismissOverlay = useCallback(async () => {
    // Update UI state immediately
    setShowFirstChallengeOverlay(false);
    
    // Only call API if user is authenticated
    if (!session.loading && session.doesSessionExist && session.userId) {
      try {
        // Make a single call to mark first challenge as seen
        const url = `/api/users/${session.userId}/mark-first-challenge-seen`;
        const response = await fetch(getApiUrl(url), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        
        if (response.ok) {
          // Update the firstChallengeStatus cache to reflect the change
          API_CACHE.firstChallengeStatus.set(session.userId, {
            response: new Response(),
            timestamp: Date.now(),
            data: { has_seen_first_challenge: true }
          });
        }
      } catch (error) {
        // Just log the error but don't change UI state
        console.error('Error marking first challenge as seen:', error);
      }
    }
  }, [session]);

  const handleStartChallenge = useCallback(() => {
    if (dailyChallenge) {
      navigate(`/problems/${dailyChallenge.problem.problem_id}`, {
        state: { returnPath: '/dashboard' }
      });
    }
  }, [dailyChallenge, navigate]);
  
  // Calculate time remaining for the daily challenge using useMemo to avoid recalculation on every render
  const timeRemaining = useMemo(() => {
    if (!dailyChallenge) return { hours: 0, minutes: 0 };
    
    const now = new Date();
    const endTime = new Date(dailyChallenge.end_time);
    const msRemaining = endTime.getTime() - now.getTime();
    const hours = Math.max(0, Math.floor(msRemaining / (1000 * 60 * 60)));
    const minutes = Math.max(0, Math.floor((msRemaining % (1000 * 60 * 60)) / (1000 * 60)));
    
    return { hours, minutes };
  }, [dailyChallenge]);
  
  const { hours, minutes } = timeRemaining;

  if (loading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <Loading />
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
      <Header />
      <div className="container mx-auto px-4 py-8 pt-24">
        <h1 className="text-2xl font-bold mb-6 flex items-center gap-2">
          <BarChart2 className="w-6 h-6 text-primary" />
          {userProfile ? `Welcome, ${userProfile.username}!` : 'Welcome to your Dashboard'}
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Daily Challenge Card - Takes full width on mobile, 2/3 on desktop */}
          <div className="lg:col-span-2">
            {dailyChallenge ? (
              <Card className={`bg-white dark:bg-gray-800 overflow-hidden ${
                dailyChallenge.submission?.completed ? 'border-green-500' : 'border-primary'
              }`}>
                <div className="relative">
                  {/* Background pattern for visual appeal */}
                  <div className="absolute inset-0 opacity-5 pattern-dots"></div>
                  
                  <CardContent className="p-6 relative">
                    <div className="flex flex-col md:flex-row md:items-start gap-6">
                      {/* Left side with icon */}
                      <div className="flex-shrink-0 flex items-center justify-center w-16 h-16 rounded-xl bg-primary/10 text-primary">
                        <Calendar className="h-10 w-10" />
                      </div>
                      
                      {/* Right side with content */}
                      <div className="flex-1">
                        <div className="flex flex-wrap items-center gap-2 mb-2">
                          <span className="text-xs font-medium px-2 py-1 rounded-full bg-primary/10 text-primary uppercase">
                            Daily Challenge
                          </span>
                          <span className="text-xs font-medium px-2 py-1 rounded-full bg-primary/10 text-primary">
                            {dailyChallenge.problem.level}
                          </span>
                          {dailyChallenge.submission?.completed && (
                            <span className="text-xs font-medium px-2 py-1 rounded-full bg-green-500/10 text-green-500 flex items-center gap-1">
                              <Check className="w-3 h-3" /> Completed
                            </span>
                          )}
                        </div>
                        
                        <h2 className="text-xl font-bold mb-2">{dailyChallenge.title}</h2>
                        <p className="text-muted-foreground mb-4">{dailyChallenge.description}</p>
                        
                        <div className="flex flex-wrap items-center gap-4 text-sm text-muted-foreground mb-4">
                          <div className="flex items-center gap-2">
                            <Clock className="w-4 h-4 text-primary" />
                            <span>
                              {hours}h {minutes}m remaining
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <Trophy className="w-4 h-4 text-primary" />
                            {dailyChallenge.submission?.completed ? (
                              <span className="text-green-500 font-medium">
                                Earned {dailyChallenge.submission.score} points
                              </span>
                            ) : (
                              <span>{dailyChallenge.points} points available</span>
                            )}
                          </div>
                        </div>
                        
                        <Button
                          onClick={handleStartChallenge}
                          className={`w-full md:w-auto ${
                            dailyChallenge.submission?.completed 
                              ? 'bg-green-500 hover:bg-green-600' 
                              : ''
                          }`}
                        >
                          {dailyChallenge.submission?.completed ? 'View Solution' : 'Start Challenge'}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </div>
              </Card>
            ) : (
              <Card className="bg-white dark:bg-gray-800">
                <CardContent className="p-8 text-center">
                  <Calendar className="w-12 h-12 text-muted-foreground mx-auto mb-4" />
                  <h3 className="text-xl font-semibold mb-2">No Daily Challenge Available</h3>
                  <p className="text-muted-foreground mb-4">Check back later for today's challenge!</p>
                  <Button
                    onClick={() => navigate('/problems')}
                    variant="outline"
                  >
                    Browse All Problems
                  </Button>
                </CardContent>
              </Card>
            )}
          </div>
          
          {/* Streak Card */}
          <div>
            <Card className="bg-white dark:bg-gray-800 h-full">
              <CardContent className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-lg font-semibold">Your Streak</h3>
                  <div className="p-2 rounded-full bg-primary/10">
                    <Flame className="w-5 h-5 text-primary" />
                  </div>
                </div>
                
                {streakInfo ? (
                  <div className="space-y-6">
                    <div className="flex flex-col items-center">
                      <div className="text-4xl font-bold text-primary mb-1">
                        {streakInfo.current_streak}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        days in a row
                      </div>
                    </div>
                    
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span>Personal best</span>
                        <span className="font-medium">{streakInfo.longest_streak} days</span>
                      </div>
                      
                      <Progress 
                        value={(streakInfo.current_streak / Math.max(streakInfo.longest_streak, 1)) * 100} 
                        className="h-2"
                      />
                    </div>
                    
                    <div className="p-3 rounded-lg bg-amber-500/10 border border-amber-500/20">
                      <div className="flex items-start gap-3">
                        <div className="pt-0.5">
                          <Zap className="w-5 h-5 text-amber-500" />
                        </div>
                        <div>
                          <h4 className="font-medium text-amber-500">Streak Status</h4>
                          <p className="text-sm text-muted-foreground">
                            {streakInfo.needs_activity_today 
                              ? "Complete today's challenge to maintain your streak!"
                              : "You've already maintained your streak today!"}
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <Button 
                      onClick={() => navigate('/challenges')}
                      variant="outline"
                      className="w-full"
                    >
                      See All Challenges
                    </Button>
                  </div>
                ) : (
                  <div className="text-center py-6">
                    <div className="text-muted-foreground mb-4">
                      No streak information available
                    </div>
                    <Button
                      onClick={handleStartChallenge}
                      variant="outline"
                      disabled={!dailyChallenge}
                    >
                      Start Today's Challenge
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      
      {/* First Challenge Overlay for new users */}
      {showFirstChallengeOverlay && (
        <FirstChallengeOverlay onDismiss={handleDismissOverlay} />
      )}
      
      {/* Debug section for development only */}
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-4 right-4 z-50">
          <Button
            size="sm"
            variant="outline"
            className="text-xs bg-red-500/10 text-red-600 dark:text-red-400 border-red-400/30"
            onClick={() => {
              // Show the overlay
              setShowFirstChallengeOverlay(true);
              console.log('Manually triggered overlay display');
            }}
          >
            Show First Challenge Overlay
          </Button>
        </div>
      )}
    </div>
  );
};

export default Dashboard; 