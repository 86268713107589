import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl } from '../config/env';
import { Button } from './ui/button';
import { Sparkles, BookOpen, Trophy, X } from 'lucide-react';

interface FirstChallengeOverlayProps {
  onDismiss: () => void;
}

interface Problem {
  id: number;
  title: string;
  difficulty: string;
  isPremium: boolean;
  isCompleted: boolean;
  isAvailable: boolean;
  locked?: boolean;
  type?: string;
  score?: number;
}

/**
 * This component shows an overlay guiding new users to their first challenge
 * It appears when a user first logs in and hasn't completed any challenges yet
 */
export function FirstChallengeOverlay({ onDismiss }: FirstChallengeOverlayProps) {
  const [easyProblem, setEasyProblem] = useState<Problem | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  
  console.log('First Challenge Overlay rendered');
  
  useEffect(() => {
    // Fetch problem #1 specifically
    const fetchProblem = async () => {
      console.log('Fetching problem #1...');
      try {
        const response = await fetch(getApiUrl('/get_problem/1'), {
          credentials: 'include'
        });

        if (!response.ok) {
          console.error('Failed to fetch problem #1:', response.status);
          setError(`Failed to fetch problem: ${response.status}`);
          return;
        }

        const data = await response.json();
        console.log('Received problem data:', data);
        
        if (data) {
          setEasyProblem(data);
        } else {
          console.log('No problem found in the response');
          setError('Could not find the tutorial problem.');
        }
      } catch (error) {
        console.error('Error fetching problem:', error);
        setError('Error fetching problem. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProblem();
  }, []);
  
  const handleStartEasyProblem = () => {
    // Navigate to the tutorial page
    navigate('/tutorial/problem');
  };
  
  const handleBrowseProblems = () => {
    onDismiss();
    navigate('/problems');
  };
  
  return (
    <div className="fixed inset-0 z-[200] overflow-y-auto flex items-center justify-center p-4 md:p-8 bg-black/40 backdrop-blur-sm">
      <div className="relative w-full max-w-lg bg-white dark:bg-gray-900 rounded-lg shadow-xl overflow-hidden animate-in zoom-in-95 duration-300">
        {/* Close button */}
        <button
          onClick={onDismiss}
          className="absolute top-4 right-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          aria-label="Close"
        >
          <X className="h-5 w-5" />
        </button>

        {/* Content */}
        <div className="p-6 md:p-8">
          {/* Sparkles Icon Header */}
          <div className="flex justify-center mb-6">
            <div className="p-3 bg-primary/10 rounded-full">
              <Sparkles className="h-8 w-8 text-primary" />
            </div>
          </div>

          <h2 className="text-2xl font-bold text-center mb-2">
            Welcome to SpreadsheetChallenges!
          </h2>
          
          <p className="text-muted-foreground text-center mb-6">
            Ready to improve your skills? Let's get started with your first challenge.
          </p>

          {/* Options */}
          <div className="space-y-4">
            {loading ? (
              <div className="flex justify-center py-6">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary"></div>
              </div>
            ) : error ? (
              <div className="text-center py-4">
                <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>
                <Button 
                  className="w-full"
                  variant="outline"
                  onClick={handleBrowseProblems}
                >
                  <BookOpen className="h-5 w-5 mr-2" />
                  Browse All Problems
                </Button>
              </div>
            ) : easyProblem ? (
              <div className="space-y-4">
                <div className="p-4 border border-primary/20 rounded-lg bg-primary/5">
                  <h3 className="font-semibold text-lg">{easyProblem.title}</h3>
                  <div className="flex items-center mt-1">
                    <span className="text-sm font-medium px-2 py-0.5 bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-100 rounded-full">
                      Easy
                    </span>
                    {easyProblem.score && (
                      <span className="text-xs ml-2 text-muted-foreground">
                        {easyProblem.score} points
                      </span>
                    )}
                  </div>
                </div>

                <Button 
                  className="w-full py-6 flex items-center justify-center gap-2"
                  onClick={handleStartEasyProblem}
                >
                  <Trophy className="h-5 w-5" />
                  Start This Challenge
                </Button>
              </div>
            ) : (
              <Button 
                className="w-full py-6 flex items-center justify-center gap-2"
                variant="outline"
                onClick={handleBrowseProblems}
              >
                <BookOpen className="h-5 w-5" />
                Browse All Problems
              </Button>
            )}
            
            {easyProblem && !loading && !error && (
              <Button 
                variant="outline"
                className="w-full"
                onClick={handleBrowseProblems}
              >
                <BookOpen className="h-5 w-5 mr-2" />
                Browse All Problems
              </Button>
            )}
          </div>
          
          {/* Extra Info */}
          <div className="mt-6 p-4 bg-primary/5 rounded-lg border border-primary/10 text-sm text-muted-foreground">
            <p>
              <span className="font-medium text-foreground">Pro Tip:</span>{' '}
              Start with easy problems to learn the basics before tackling more challenging ones!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
} 