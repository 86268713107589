import React from "react";

const GridBackground: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="relative min-h-screen w-full">
      <div 
        className="fixed inset-0 pointer-events-none transition-opacity duration-300"
        style={{
          background: `
            linear-gradient(to right, rgb(62 180 137 / var(--grid-opacity)) 1px, transparent 1px),
            linear-gradient(to bottom, rgb(62 180 137 / var(--grid-opacity)) 1px, transparent 1px)
          `,
          backgroundSize: '32px 24px',
          opacity: '1',
          transform: 'skewY(-6deg)',
          transformOrigin: '50% 0',
          width: '300vw',
          height: '2000vh',
          left: '-100vw',
          top: '0',
          '--grid-opacity': '0.1'  // Darker lines in light mode
        } as React.CSSProperties}
      />
      <div className="relative z-10 dark:[--grid-opacity:0.05]">
        {children}
      </div>
    </div>
  );
};

export default GridBackground;