import React from "react";
import { cn } from "../../lib/utils";

interface CustomAlertProps {
  children: React.ReactNode;
  variant?: 'success' | 'error' | 'warning' | 'info';
  className?: string;
}

export function CustomAlert({ children, variant = 'info', className }: CustomAlertProps) {
  return (
    <div
      className={cn(
        "rounded-lg border p-4",
        {
          'bg-green-50 border-green-200 text-green-700': variant === 'success',
          'bg-red-50 border-red-200 text-red-700': variant === 'error',
          'bg-yellow-50 border-yellow-200 text-yellow-700': variant === 'warning',
          'bg-blue-50 border-blue-200 text-blue-700': variant === 'info',
        },
        className
      )}
    >
      {children}
    </div>
  );
} 