import React from "react";
import { MessageSquare } from "lucide-react";
import FeedbackDialog from "./feedback-dialog";

const FloatingFeedbackButton: React.FC = () => {
  return (
    <div className="fixed right-6 bottom-6 z-50">
      <FeedbackDialog
        trigger={
          <button className="flex items-center justify-center h-12 w-12 rounded-full bg-primary text-primary-foreground shadow-lg hover:bg-primary/90 transition-colors">
            <MessageSquare className="h-5 w-5" />
            <span className="sr-only">Feedback</span>
          </button>
        }
      />
    </div>
  );
};

export default FloatingFeedbackButton;
