import React, { useState, useEffect, useMemo } from 'react';
import { Button } from './ui/button';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface TutorialStep {
  id: string;
  title: string;
  description: string;
  targetSelector: string; // CSS selector for the element to highlight
  position: 'top' | 'right' | 'bottom' | 'left';
  offset?: number; // Optional offset for positioning
}

interface ProblemTutorialOverlayProps {
  onDismiss: () => void;
}

export const ProblemTutorialOverlay: React.FC<ProblemTutorialOverlayProps> = ({ onDismiss }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [highlightPosition, setHighlightPosition] = useState<{ top: number; left: number; width: number; height: number }>({
    top: 0,
    left: 0,
    width: 0,
    height: 0
  });
  const navigate = useNavigate();

  // Define the tutorial steps with exact selectors matching the real editor's HTML structure
  const tutorialSteps = useMemo(() => [
    {
      id: 'problem-description',
      title: 'Problem Description',
      description: 'This is where you can read what the problem is asking you to solve. Make sure to understand all the requirements before attempting a solution.',
      targetSelector: '.p-6 > p.text-gray-600', // Target the exact paragraph with problem description
      position: 'right',
      offset: 20
    },
    {
      id: 'data-preview',
      title: 'Data Preview',
      description: 'This section shows a preview of the data you will be working with. Understanding the data structure is crucial for solving the problem correctly.',
      targetSelector: 'table.min-w-full', // Target the data table
      position: 'top',
      offset: 20
    },
    {
      id: 'cell-references',
      title: 'Cell References',
      description: 'These are the Excel cell references you will need to use in your formula. Pay attention to the range of cells that contain your data.',
      targetSelector: '#cell-references-grid', // Target the cell references grid
      position: 'right',
      offset: 20
    },
    {
      id: 'hints',
      title: 'Hints',
      description: 'If you get stuck, you can use hints to help guide you. Remember that using hints will reduce your potential score for the problem.',
      targetSelector: '#hints-section', // Target the hints section
      position: 'right',
      offset: 20
    },
    {
      id: 'formula-editor',
      title: 'Formula Editor',
      description: 'Write your Excel formula here. Make sure to use proper Excel syntax and reference the correct cells.',
      targetSelector: '#formula-editor', // Target the formula editor textarea by ID
      position: 'left',
      offset: 20
    },
    {
      id: 'submit-button',
      title: 'Submit Your Solution',
      description: 'Once you have written your formula and are confident in your solution, click Submit to check if it is correct.',
      targetSelector: '#submit-button', // Target the submit button by ID
      position: 'left',
      offset: 20
    }
  ], []);

  useEffect(() => {
    // Wait for the DOM to be fully loaded before trying to find elements
    const delay = setTimeout(() => {
      updateHighlightPosition();
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  const updateHighlightPosition = () => {
    const currentStep = tutorialSteps[currentStepIndex];
    if (!currentStep) return;

    let targetElement: Element | null = null;
    
    try {
      // Standard CSS selector
      targetElement = document.querySelector(currentStep.targetSelector);
      
      // If not found with the primary selector, try alternative selectors
      if (!targetElement) {
        // Fallback selectors for common elements
        if (currentStep.id === 'problem-description') {
          targetElement = document.querySelector('.p-6 > p') || 
                          document.querySelector('.text-gray-600') ||
                          document.querySelector('.text-gray-300');
        } else if (currentStep.id === 'data-preview') {
          targetElement = document.querySelector('table') || 
                          document.querySelector('.overflow-x-auto');
        } else if (currentStep.id === 'cell-references') {
          targetElement = document.querySelector('h2:nth-of-type(1)') ||
                          document.querySelector('.cell-reference-item');
        } else if (currentStep.id === 'hints') {
          targetElement = document.querySelector('#hints-title') ||
                          document.querySelector('.hint-container');
        } else if (currentStep.id === 'formula-editor') {
          targetElement = document.querySelector('textarea');
        } else if (currentStep.id === 'submit-button') {
          const buttons = document.querySelectorAll('button');
          // Find the button with "Submit" text
          targetElement = Array.from(buttons).find(btn => 
            btn.textContent?.includes('Submit')
          ) || null;
        }
      }

      if (targetElement) {
        console.log(`Found element for ${currentStep.id}:`, targetElement);
        const rect = targetElement.getBoundingClientRect();
        
        setHighlightPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height
        });

        // Scroll the element into view if it's not already visible
        if (!isElementInViewport(targetElement)) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          
          // After scrolling, update position again to ensure accuracy
          setTimeout(() => {
            const updatedRect = targetElement?.getBoundingClientRect();
            if (updatedRect) {
              setHighlightPosition({
                top: updatedRect.top + window.scrollY,
                left: updatedRect.left + window.scrollX,
                width: updatedRect.width,
                height: updatedRect.height
              });
            }
          }, 400);
        }
      } else {
        console.warn(`Element not found for selector: ${currentStep.targetSelector}`);
      }
    } catch (error) {
      console.error(`Error finding element for step ${currentStep.id}:`, error);
    }
  };
  
  // Helper function to check if an element is in the viewport
  const isElementInViewport = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    // Update position when step changes
    updateHighlightPosition();

    // Also update on window resize
    window.addEventListener('resize', updateHighlightPosition);
    
    // And update when DOM changes
    const observer = new MutationObserver(() => {
      updateHighlightPosition();
    });
    
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
    
    return () => {
      window.removeEventListener('resize', updateHighlightPosition);
      observer.disconnect();
    };
  }, [currentStepIndex, tutorialSteps]);

  const handleNext = () => {
    if (currentStepIndex < tutorialSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      // Last step, dismiss the tutorial
      onDismiss();
    }
  };

  const handleBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handleSkip = () => {
    onDismiss();
  };

  const currentStep = tutorialSteps[currentStepIndex];
  
  // Calculate tooltip position based on highlight position and step position preference
  const getTooltipPosition = () => {
    if (!currentStep) return { top: 0, left: 0 };
    
    const tooltipWidth = 300; // Estimated tooltip width
    const tooltipHeight = 200; // Estimated tooltip height
    let padding = currentStep.offset || 20; // Space between highlight and tooltip
    
    let top = 0;
    let left = 0;
    
    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    switch (currentStep.position) {
      case 'top':
        top = highlightPosition.top - tooltipHeight - padding;
        left = highlightPosition.left + (highlightPosition.width / 2) - (tooltipWidth / 2);
        break;
      case 'right':
        top = highlightPosition.top + (highlightPosition.height / 2) - (tooltipHeight / 2);
        left = highlightPosition.left + highlightPosition.width + padding;
        break;
      case 'bottom':
        top = highlightPosition.top + highlightPosition.height + padding;
        left = highlightPosition.left + (highlightPosition.width / 2) - (tooltipWidth / 2);
        break;
      case 'left':
        top = highlightPosition.top + (highlightPosition.height / 2) - (tooltipHeight / 2);
        left = highlightPosition.left - tooltipWidth - padding;
        break;
    }
    
    // Ensure tooltip stays within viewport bounds
    if (left < 20) left = 20;
    if (left + tooltipWidth > viewportWidth - 20) left = viewportWidth - tooltipWidth - 20;
    if (top < 20) top = 20;
    if (top + tooltipHeight > viewportHeight - 20) top = viewportHeight - tooltipHeight - 20;
    
    return { top, left };
  };
  
  const tooltipPosition = getTooltipPosition();
  
  return (
    <div className="fixed inset-0 z-[1000] pointer-events-auto">
      {/* Slightly translucent overlay - not fully blurred so content remains visible */}
      <div className="absolute inset-0 bg-black/30" onClick={handleSkip} />
      
      {/* Green highlight border around the current target element */}
      <div 
        className="absolute border-2 border-green-500 rounded-md z-[1001] pointer-events-none"
        style={{
          top: highlightPosition.top,
          left: highlightPosition.left,
          width: highlightPosition.width,
          height: highlightPosition.height,
          boxShadow: '0 0 0 4px rgba(34, 197, 94, 0.3)'
        }}
      />
      
      {/* Create a cutout effect - this makes the highlighted area fully visible */}
      <div 
        className="absolute bg-transparent z-[1001] pointer-events-none"
        style={{
          top: highlightPosition.top,
          left: highlightPosition.left,
          width: highlightPosition.width,
          height: highlightPosition.height,
          boxShadow: '0 0 0 2000px rgba(0, 0, 0, 0.3)'
        }}
      />
      
      {/* Tooltip */}
      <div 
        className="absolute bg-white dark:bg-gray-800 w-[300px] p-4 rounded-lg shadow-xl z-[1002] pointer-events-auto border border-green-500"
        style={{
          top: tooltipPosition.top,
          left: tooltipPosition.left
        }}
      >
        {/* Close button */}
        <button 
          onClick={handleSkip}
          className="absolute top-2 right-2 w-6 h-6 rounded-md flex items-center justify-center text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <X className="w-4 h-4" />
        </button>
        
        {/* Step counter */}
        <div className="text-xs text-gray-500 dark:text-gray-400 mb-1">
          Step {currentStepIndex + 1} of {tutorialSteps.length}
        </div>
        
        {/* Title */}
        <h3 className="text-lg font-bold text-gray-800 dark:text-white mb-2">
          {currentStep?.title}
        </h3>
        
        {/* Description */}
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
          {currentStep?.description}
        </p>
        
        {/* Navigation buttons */}
        <div className="flex justify-between">
          <Button 
            onClick={handleBack} 
            variant="outline"
            disabled={currentStepIndex === 0}
            className="px-3 py-1 h-auto text-sm"
          >
            Back
          </Button>
          
          <div className="flex space-x-2">
            <Button 
              onClick={handleSkip} 
              variant="ghost"
              className="px-3 py-1 h-auto text-sm"
            >
              Skip
            </Button>
            
            <Button 
              onClick={handleNext} 
              variant="default"
              className="px-3 py-1 h-auto text-sm"
            >
              {currentStepIndex === tutorialSteps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemTutorialOverlay;