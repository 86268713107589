import type { Metric } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then((vitals: unknown) => {
      const webVitals = vitals as {
        getCLS: (onReport: (metric: Metric) => void) => void;
        getFID: (onReport: (metric: Metric) => void) => void;
        getFCP: (onReport: (metric: Metric) => void) => void;
        getLCP: (onReport: (metric: Metric) => void) => void;
        getTTFB: (onReport: (metric: Metric) => void) => void;
      };
      webVitals.getCLS(onPerfEntry);
      webVitals.getFID(onPerfEntry);
      webVitals.getFCP(onPerfEntry);
      webVitals.getLCP(onPerfEntry);
      webVitals.getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals; 