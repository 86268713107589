import React from "react";
import { Shield, Lock, Eye, FileText, Mail } from "lucide-react";
import Header from "../components/Header";
import GridBackground from "../components/GridBackground";
import { Card } from "../components/ui/card";

const PrivacyPolicy = () => {
  const sections = [
    {
      icon: <Shield className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Information Collection",
      content: `We collect information that you provide directly to us, including:
        • Account information (name, email, etc.)
        • Profile information
        • Usage data and preferences
        • Communication data
        
        We also collect basic, anonymous analytics data through GoatCounter to understand how users interact with our service. This data is minimal and does not personally identify you.`,
    },
    {
      icon: <Lock className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Data Security",
      content: `We implement appropriate security measures to protect your personal information, including:
        • Encryption of sensitive data
        • Regular security assessments
        • Access controls and authentication
        • Secure data storage`,
    },
    {
      icon: <Eye className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Data Usage",
      content: `We use your information to:
        • Provide and improve our services
        • Personalize your experience
        • Communicate with you
        • Analyze aggregated, anonymous usage patterns
        • Ensure platform security`,
    },
    {
      icon: <FileText className="h-6 w-6 text-[#3eb489] dark:text-[#4ade80]" />,
      title: "Your Rights",
      content: `You have the right to:
        • Access your personal data
        • Request data correction
        • Request data deletion
        • Opt-out of marketing
        • Data portability`,
    },
  ];

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="min-h-[calc(100vh-64px)] flex items-center justify-center py-16">
          <div className="w-full max-w-4xl mx-auto px-4">
            <Card className="overflow-hidden border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 backdrop-blur-xl">
              <div className="p-8">
                {/* Header */}
                <div className="text-center mb-12">
                  <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
                  <p className="text-gray-600 dark:text-gray-400">
                    Last updated: {new Date().toLocaleDateString()}
                  </p>
                </div>

                {/* Overview */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-4">Overview</h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    At Spreadsheet Challenges, we take your privacy seriously. This Privacy Policy describes how we collect,
                    use, and handle your personal information when you use our services. By using our platform,
                    you agree to the collection and use of information in accordance with this policy.
                  </p>
                </div>

                {/* Main Sections */}
                <div className="grid grid-cols-1 gap-8 mb-12">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30"
                    >
                      <div className="flex items-center gap-3 mb-4">
                        {section.icon}
                        <h3 className="text-xl font-semibold">{section.title}</h3>
                      </div>
                      <div className="text-gray-600 dark:text-gray-400 whitespace-pre-line">
                        {section.content}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Third Party Services */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Third-Party Services</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30">
                    <p className="text-gray-600 dark:text-gray-400 mb-6">
                      We use trusted third-party services to help us operate our platform:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="p-4 rounded-lg bg-white/30 dark:bg-gray-900/30">
                        <h3 className="font-semibold mb-2">GoatCounter</h3>
                        <p className="text-gray-600 dark:text-gray-400">For privacy-focused website analytics that collects minimal data without using cookies by default or tracking individuals across sites</p>
                      </div>
                      <div className="p-4 rounded-lg bg-white/30 dark:bg-gray-900/30">
                        <h3 className="font-semibold mb-2">Supertokens</h3>
                        <p className="text-gray-600 dark:text-gray-400">For secure user authentication using HTTP-only cookies to store session information and maintain logged-in state</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Analytics */}
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">Analytics</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30">
                    <p className="text-gray-600 dark:text-gray-400 mb-6">
                      We use GoatCounter for analytics, which is designed to be privacy-friendly:
                    </p>
                    <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                      <li>No cookies are used by default</li>
                      <li>No tracking across different websites</li>
                      <li>No collection of personal information</li>
                      <li>No detailed individual visitor profiles</li>
                      <li>Data collected includes: page URL, referrer header, screen size, country of origin (based on IP), browser, and OS</li>
                      <li>IP addresses are not stored in a retrievable format</li>
                    </ul>
                  </div>
                </div>

                {/* Contact */}
                <div>
                  <h2 className="text-2xl font-semibold mb-6">Contact Us</h2>
                  <div className="p-6 rounded-lg border border-gray-200 dark:border-gray-700 bg-white/30 dark:bg-gray-800/30 text-center">
                    <div className="flex items-center justify-center gap-2 mb-4">
                      <Mail className="h-5 w-5 text-[#3eb489] dark:text-[#4ade80]" />
                      <p className="text-gray-600 dark:text-gray-400">
                        For privacy-related inquiries, please contact us at:
                      </p>
                    </div>
                    <a
                      href="mailto:info@spreadsheetchallenges.com"
                      className="text-[#3eb489] dark:text-[#4ade80] hover:underline"
                    >
                      info@spreadsheetchallenges.com
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
  );
};

export default PrivacyPolicy; 