interface EnvConfig {
  APP_NAME: string;
  BACKEND_URL: string;
  FRONTEND_URL: string;
  NODE_ENV: string;
  AUTH_API_PATH: string;
  AUTH_WEBSITE_PATH: string;
  ENABLE_PREMIUM_FEATURES: boolean;
  ENABLE_SOCIAL_LOGIN: boolean;
  COOKIE_DOMAIN?: string;
}

// Provide fallback values for development and ensure type safety
export const config: EnvConfig = {
  APP_NAME: process.env.REACT_APP_APP_NAME || 'spreadsheetchallenges',
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000',
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000',
  NODE_ENV: process.env.NODE_ENV || 'development',
  AUTH_API_PATH: process.env.REACT_APP_AUTH_API_PATH || '/auth',
  AUTH_WEBSITE_PATH: process.env.REACT_APP_AUTH_WEBSITE_PATH || '/auth',
  ENABLE_PREMIUM_FEATURES: process.env.REACT_APP_ENABLE_PREMIUM_FEATURES === 'true',
  ENABLE_SOCIAL_LOGIN: process.env.REACT_APP_ENABLE_SOCIAL_LOGIN === 'true',
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN
};

// Log environment configuration during initialization
console.log('Initializing with environment variables:', {
  APP_NAME: process.env.REACT_APP_APP_NAME,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  AUTH_API_PATH: process.env.REACT_APP_AUTH_API_PATH,
  AUTH_WEBSITE_PATH: process.env.REACT_APP_AUTH_WEBSITE_PATH,
  ENABLE_PREMIUM_FEATURES: process.env.REACT_APP_ENABLE_PREMIUM_FEATURES,
  ENABLE_SOCIAL_LOGIN: process.env.REACT_APP_ENABLE_SOCIAL_LOGIN,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  NODE_ENV: process.env.NODE_ENV
});

// Validate that all required environment variables are present in production
if (process.env.NODE_ENV === 'production') {
  const requiredVars = ['REACT_APP_BACKEND_URL', 'REACT_APP_FRONTEND_URL'];
  const missing = requiredVars.filter(varName => !process.env[varName]);
  
  if (missing.length > 0) {
    throw new Error(`Missing required environment variables: ${missing.join(', ')}`);
  }
}

// Helper function to get API endpoint URLs
export const getApiUrl = (path: string): string => {
  return `${config.BACKEND_URL}${path}`;
}; 