import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { Loading } from '../components/ui/loading';
import { CustomAlert } from '../components/ui/custom-alert';
import { Card, CardContent } from '../components/ui/card';
import { Progress } from '../components/ui/progress';
import { Button } from '../components/ui/button';
import { BookOpen, CheckCircle, Lock, Trophy } from 'lucide-react';
import { getApiUrl } from '../config/env';
import { useSubscription } from '../hooks/use-subscription';
import PremiumModal from '../components/premium-modal';

interface LearningPath {
  path_id: number;
  title: string;
  description: string;
  icon_url: string;
  progress: {
    completed_steps: number;
    current_step: number;
    completed_at: string | null;
    started_at: string | null;
  };
  total_steps: number;
}

export default function LearningPaths() {
  const [paths, setPaths] = useState<LearningPath[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const navigate = useNavigate();
  const { isSubscribed, loading: subscriptionLoading } = useSubscription();

  useEffect(() => {
    // If subscription status is loaded and user is not premium, show the premium modal
    if (!subscriptionLoading && !isSubscribed) {
      setShowPremiumModal(true);
      setLoading(false);
      return;
    }

    // Only fetch paths if user is premium
    if (!subscriptionLoading && isSubscribed) {
      fetchPaths();
    }
  }, [navigate, subscriptionLoading, isSubscribed]);

  const fetchPaths = async () => {
    try {
      const response = await fetch(getApiUrl('/api/learning-paths'), {
        credentials: 'include'
      });

      if (response.status === 401) {
        navigate('/auth');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch learning paths');
      }

      const data = await response.json();
      setPaths(data.learning_paths);
    } catch (err) {
      setError('Failed to load learning paths. Please try again later.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading || subscriptionLoading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <Loading />
        </div>
      </div>
    );
  }

  if (showPremiumModal) {
    return (
      <div className="min-h-screen">
        <Header />
        <PremiumModal
          open={showPremiumModal}
          onClose={() => navigate('/problems')}
          onUpgrade={() => navigate('/pricing')}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-5xl mx-auto">
          {/* Header Section */}
          <div className="mb-8">
            <h1 className="text-3xl font-bold mb-2 flex items-center gap-2">
              <BookOpen className="h-8 w-8 text-primary" />
              Learning Paths
            </h1>
            <p className="text-muted-foreground">
              Follow structured learning paths to master spreadsheet skills step by step.
            </p>
          </div>

          {error ? (
            <CustomAlert variant="error" className="mb-6">
              {error}
            </CustomAlert>
          ) : (
            <div className="grid gap-6">
              {paths.map((path) => {
                const progress = Math.round((path.progress.completed_steps / path.total_steps) * 100);
                const isCompleted = path.progress.completed_at !== null;
                const isStarted = path.progress.started_at !== null;

                return (
                  <Card 
                    key={path.path_id}
                    className="relative overflow-hidden transition-all duration-300 hover:shadow-lg"
                  >
                    <CardContent className="p-6">
                      <div className="flex items-start gap-6">
                        {/* Icon */}
                        <div className="shrink-0">
                          {path.icon_url ? (
                            <img 
                              src={path.icon_url} 
                              alt={path.title}
                              className="w-16 h-16 rounded-lg"
                            />
                          ) : (
                            <div className="w-16 h-16 rounded-lg bg-primary/10 flex items-center justify-center">
                              <BookOpen className="h-8 w-8 text-primary" />
                            </div>
                          )}
                        </div>

                        {/* Content */}
                        <div className="flex-1">
                          <div className="flex items-center gap-2 mb-2">
                            <h2 className="text-xl font-semibold">{path.title}</h2>
                            {isCompleted && (
                              <CheckCircle className="h-5 w-5 text-green-500" />
                            )}
                          </div>
                          <p className="text-muted-foreground mb-4">
                            {path.description}
                          </p>
                          
                          {/* Progress Section */}
                          <div className="space-y-2">
                            <div className="flex items-center justify-between text-sm">
                              <span className="text-muted-foreground">
                                {isCompleted 
                                  ? 'Completed'
                                  : isStarted
                                  ? `${path.progress.completed_steps} of ${path.total_steps} steps completed`
                                  : 'Not started'
                                }
                              </span>
                              <span className="font-medium">{progress}%</span>
                            </div>
                            <Progress value={progress} className="h-2" />
                          </div>
                        </div>

                        {/* Action Button */}
                        <div className="ml-4">
                          <Button
                            onClick={() => navigate(`/learning-paths/${path.path_id}`)}
                            variant={isCompleted ? "outline" : "default"}
                            className="min-w-[120px]"
                          >
                            {isCompleted 
                              ? 'Review Path'
                              : isStarted
                              ? 'Continue'
                              : 'Start Path'
                            }
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}

              {paths.length === 0 && (
                <div className="text-center py-12">
                  <BookOpen className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
                  <h3 className="text-lg font-semibold mb-2">No learning paths available</h3>
                  <p className="text-muted-foreground">
                    Check back later for new learning paths.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 