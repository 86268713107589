import React from 'react';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Loading } from './ui/loading';

interface SessionAuthProps {
    children: React.ReactNode;
}

export const SessionAuth: React.FC<SessionAuthProps> = ({ children }) => {
    const session = useSessionContext() as SessionContextType;
    const navigate = useNavigate();

    useEffect(() => {
        if (!session.loading && !session.doesSessionExist) {
            navigate('/auth');
        }
    }, [session, navigate]);

    if (session.loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Loading />
            </div>
        );
    }

    if (!session.doesSessionExist) {
        return null;
    }

    return <>{children}</>;
}; 