import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Trophy, Medal, Star } from "lucide-react";
import { CustomAlert } from "../components/ui/custom-alert";
import { Loading } from "../components/ui/loading";
import Header from "../components/Header";
import GridBackground from "../components/GridBackground";
import { getApiUrl } from '../config/env';

interface LeaderboardUser {
  id: string;
  username: string;
  avatar_url?: string;
  total_score: number;
  problems_solved: number;
  rank: number;
  average_score: string;
}

const Leaderboard: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(getApiUrl('/api/leaderboard'), {
          credentials: 'include'
        });

        if (response.status === 401) {
          navigate('/auth');
          return;
        }

        const data = await response.json();
        const formattedData = data.rankings.map((user: any) => ({
          ...user,
          id: user.id || Math.random().toString(),
          average_score: user.problems_solved > 0
            ? (user.total_score / user.problems_solved).toFixed(1)
            : '0'
        }));

        setLeaderboardData(formattedData);
      } catch (err) {
        setError('Failed to fetch leaderboard data. Please try again later.');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [navigate]);

  const getRankDisplay = (rank: number) => {
    switch (rank) {
      case 1:
        return <Trophy className="h-5 w-5 text-yellow-400" />;
      case 2:
        return <Medal className="h-5 w-5 text-slate-300" />;
      case 3:
        return <Medal className="h-5 w-5 text-amber-600" />;
      default:
        return <span className="text-muted-foreground font-medium">{rank}</span>;
    }
  };

  if (loading) {  
    return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
          <Header />
          <div className="flex items-center justify-center h-[calc(100vh-64px)]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#3eb489] dark:border-[#4ade80]"></div>
          </div>
        </div>
    );
  }

  return (
    <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="min-h-[calc(100vh-64px)] flex items-center justify-center">
          <div className="w-full max-w-4xl mx-auto px-4">
            {error && (
              <CustomAlert variant="error" className="mb-6">
                {error}
              </CustomAlert>
            )}

            {leaderboardData.length === 0 && !loading && !error ? (
              <CustomAlert variant="info" className="mb-6">
                No rankings available yet. Be the first to solve problems and appear on the leaderboard!
              </CustomAlert>
            ) : (
              <div className="rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-900/50 p-8">
                <div className="flex items-center justify-between mb-8">
                  <h2 className="text-3xl font-bold flex items-center gap-3 text-gradient">
                    <Trophy className="h-8 w-8 text-yellow-400" />
                    Global Leaderboard
                  </h2>
                  <div className="flex items-center gap-2 text-sm text-muted-foreground bg-gray-100 dark:bg-gray-800 py-2 px-4 rounded-full">
                    <Star className="h-4 w-4 text-yellow-400" /> Updated every 5 minutes
                  </div>
                </div>

                <div className="relative overflow-hidden rounded-lg">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#3eb489]/5 via-[#3eb489]/2 to-transparent dark:from-[#3eb489]/10 dark:via-[#3eb489]/5 dark:to-transparent pointer-events-none" />
                  <Table>
                    <TableHeader>
                      <TableRow className="hover:bg-transparent border-b border-gray-200 dark:border-gray-800">
                        <TableHead className="w-20 text-center">Rank</TableHead>
                        <TableHead>Username</TableHead>
                        <TableHead className="text-right">Score</TableHead>
                        <TableHead className="text-right">Problems</TableHead>
                        <TableHead className="text-right">Avg. Score</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {leaderboardData.map((entry) => (
                        <TableRow
                          key={entry.id}
                          className={`
                            cursor-pointer 
                            transition-colors
                            hover:bg-gray-100/50 dark:hover:bg-white/5
                            ${entry.rank <= 3 ? 'font-medium' : ''}
                            ${entry.rank === 1 ? 'bg-yellow-500/5 dark:bg-yellow-500/10' : ''}
                            ${entry.rank === 2 ? 'bg-slate-300/5 dark:bg-slate-300/10' : ''}
                            ${entry.rank === 3 ? 'bg-amber-600/5 dark:bg-amber-600/10' : ''}
                          `}
                        >
                          <TableCell className="text-center">
                            <div className="flex items-center justify-center">
                              {getRankDisplay(entry.rank)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <span className={`
                                ${entry.rank === 1 ? 'text-yellow-400' : ''}
                                ${entry.rank === 2 ? 'text-slate-300' : ''}
                                ${entry.rank === 3 ? 'text-amber-600' : ''}
                              `}>
                                {entry.username}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className="text-right font-mono">{entry.total_score.toLocaleString()}</TableCell>
                          <TableCell className="text-right font-mono">{entry.problems_solved}</TableCell>
                          <TableCell className="text-right">
                            <div className="flex items-center justify-end gap-1">
                              <Star className="h-4 w-4 text-yellow-400" />
                              {entry.average_score}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default Leaderboard; 