import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AchievementsList } from '../components/achievements/achievements-list';
import { Loading } from '../components/ui/loading';
import { CustomAlert } from '../components/ui/custom-alert';
import Header from '../components/Header';
import GridBackground from '../components/GridBackground';
import { getApiUrl } from '../config/env';
import { handleNewAchievements } from '../utils/achievement-handler';
import { checkForNewAchievements } from '../utils/achievement-tracker';

interface Achievement {
  achievement_id: number;
  name: string;
  description: string;
  icon_url: string;
  badge_color: string;
  points: number;
  awarded: boolean;
  awarded_at: string | null;
}

export default function Achievements() {
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const response = await fetch(getApiUrl('/api/achievements'), {
          credentials: 'include'
        });

        if (response.status === 401) {
          navigate('/auth');
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch achievements');
        }

        const data = await response.json();
        setAchievements(data.achievements);
        
        // Even though we're on the Achievements page, there might be 
        // meta-achievements for viewing the achievements page itself
        checkForNewAchievements();
      } catch (err) {
        setError('Failed to load achievements. Please try again later.');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAchievements();
  }, [navigate]);

  if (loading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <Loading />
        </div>
      </div>
    );
  }

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="container mx-auto px-4 py-8">
          {error ? (
            <CustomAlert variant="error" className="mb-6">
              {error}
            </CustomAlert>
          ) : (
            <AchievementsList 
              achievements={achievements}
              className="max-w-4xl mx-auto"
            />
          )}
        </div>
      </div>
  );
} 