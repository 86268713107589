import React from 'react';
import { cn } from '../../lib/utils';

interface LoadingProps {
    className?: string;
}

export function Loading({ className }: LoadingProps) {
    return (
        <div className={cn("flex items-center justify-center", className)}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
} 