import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import Header from './Header';
import { Loading } from './ui/loading';
import { getApiUrl } from '../config/env';

interface RoleInfo {
    role: string;
    permissions: string[];
    [key: string]: any;
}

const RoleTester: React.FC = () => {
    const [roleInfo, setRoleInfo] = useState<RoleInfo | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchRoleInfo = async () => {
        try {
            setLoading(true);
            const response = await axios.get(getApiUrl('/test/my-role'), {
                withCredentials: true
            });
            setRoleInfo(response.data);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const setFreeRole = async () => {
        try {
            setLoading(true);
            await axios.post(getApiUrl('/test/set-free'), {}, {
                withCredentials: true
            });
            await fetchRoleInfo();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const upgradeToPremium = async () => {
        try {
            setLoading(true);
            await axios.post(getApiUrl('/test/upgrade'), {}, {
                withCredentials: true
            });
            await fetchRoleInfo();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRoleInfo();
    }, []);

    return (
        <>
            <Header />
            <div className="container mx-auto px-4 mt-20">
                <h1 className="text-3xl font-bold mb-6">Role Tester</h1>

                {error && (
                    <Alert variant="destructive" className="mb-4">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                <Card className="mb-6">
                    <CardContent className="pt-6">
                        <h2 className="text-xl font-semibold mb-4">Current Role Info:</h2>
                        {loading ? (
                            <Loading className="py-4" />
                        ) : (
                            <pre className="bg-muted/50 p-4 rounded-lg overflow-auto">
                                {JSON.stringify(roleInfo, null, 2)}
                            </pre>
                        )}
                    </CardContent>
                </Card>

                <div className="flex flex-wrap gap-4">
                    <Button
                        variant="outline"
                        onClick={setFreeRole}
                        disabled={loading}
                    >
                        Set Free Role
                    </Button>
                    <Button
                        onClick={upgradeToPremium}
                        disabled={loading}
                        className="bg-primary hover:bg-primary/90"
                    >
                        Upgrade to Premium
                    </Button>
                    <Button
                        variant="outline"
                        onClick={fetchRoleInfo}
                        disabled={loading}
                    >
                        Refresh Role Info
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RoleTester; 