import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button } from "../components/ui/button";
import { LightbulbIcon, Upload, Download, Trophy, ArrowLeft } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Loading } from "../components/ui/loading";
import { CustomAlert } from "../components/ui/custom-alert";
import PremiumModal from "../components/premium-modal";
import Header from "../components/Header";
import { useTheme } from "../components/theme-provider";
import { getApiUrl } from '../config/env';
import { showAchievementToast } from "../components/achievements/achievement-toast";
import { toast } from "../components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import MiniSheet from "../components/mini-sheet";
import { handleNewAchievements } from '../utils/achievement-handler';
import { checkForAchievementsAfterCompletion } from '../utils/achievement-tracker';

interface Problem {
  title: string;
  statement: string;
  hints: string[];
  data: string;
  file_location: string;
  maxScore: number;
  isCompleted: boolean;
  constraints?: { cell: string }[];
  requires_premium?: boolean;
  isPremium?: boolean;
  limited_preview?: boolean;
  mini_sheet?: {
    cell_ranges: string[];
    headers: string[];
    number_of_lines: number;
    example_data: any[][];
  };
}

interface EditorProps {
  tutorialMode?: boolean;
  fixedProblemId?: string;
  onFinish?: () => void;
  onProblemComplete?: (score: number) => void;
}

const Editor: React.FC<EditorProps> = ({ tutorialMode = false, fixedProblemId, onFinish, onProblemComplete }) => {
  const { problemId: routeParamProblemId } = useParams();
  const location = useLocation();
  const [formulaType, setFormulaType] = useState<string>("excel");
  const [problemData, setProblemData] = useState<Problem | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [resultMessage, setResultMessage] = useState<string | React.ReactNode>("");
  const [userFormula, setUserFormula] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const navigate = useNavigate();
  const [currentAttemptHints, setCurrentAttemptHints] = useState<Set<number>>(new Set());
  const [potentialScore, setPotentialScore] = useState<number | null>(null);
  const [isProblemCompleted, setIsProblemCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [earnedScore, setEarnedScore] = useState(0);
  const { theme } = useTheme();
  const [validHints, setValidHints] = useState<string[]>([]);
  const [returnPath, setReturnPath] = useState('');

  // Use either the fixed problem ID (for tutorial) or the route param
  const problemId = fixedProblemId || routeParamProblemId;

  useEffect(() => {
    if (location.state && location.state.returnPath) {
      setReturnPath(location.state.returnPath);
    } else {
      setReturnPath('/problems');
    }
  }, [location]);

  useEffect(() => {
    fetch(getApiUrl(`/get_problem/${problemId}`), {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 401) {
          navigate('/auth');
          return null;
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          // Check if problem requires premium (skip this check in tutorial mode)
          if (!tutorialMode && (data.requires_premium || data.limited_preview)) {
            setShowPremiumModal(true);
            setProblemData({
              title: data.title || 'Premium Problem',
              statement: 'This premium problem requires a subscription.',
              hints: [],
              data: '',
              file_location: '',
              maxScore: 0,
              isCompleted: false,
              isPremium: true
            });
            setLoading(false);
            return;
          }
          
          setProblemData(data);
          setIsProblemCompleted(data.isCompleted);
          if (data.isCompleted) {
            setResultMessage(<span style={{ color: '#4caf50' }}>{"You've already completed this problem!"}</span>);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [problemId, navigate, tutorialMode]);

  useEffect(() => {
    if (problemData?.maxScore) {
      setPotentialScore(problemData.maxScore);
    }
  }, [problemData]);

  useEffect(() => {
    if (problemData?.hints) {
      const filtered = problemData.hints.filter(hint =>
        hint !== "NULL" &&
        hint !== undefined &&
        typeof hint === 'string' &&
        hint.trim() !== ''
      );
      setValidHints(filtered);
    }
  }, [problemData]);

  const handleChange = (value: string) => {
    setFormulaType(value);
  };

  const calculateHintPenalty = (hintNumber: number): number => {
    switch (hintNumber) {
      case 0: return 10;  // First hint
      case 1: return 15;  // Second hint
      case 2: return 30;  // Third hint
      default: return 0;
    }
  };

  const handleHintExpand = async (index: number) => {
    if (!currentAttemptHints.has(index)) {
      const penalty = calculateHintPenalty(currentAttemptHints.size);
      setCurrentAttemptHints(prev => new Set(prev).add(index));
      setPotentialScore(prev => prev !== null ? Math.max(0, prev - penalty) : 0);

      toast({
        title: `Hint ${index + 1} Revealed`,
        description: `You will receive -${penalty} points for using this hint.`,
        variant: "default",
      });

      try {
        await fetch(getApiUrl(`/track_hint/${problemId}`), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ hint_number: index + 1 })
        });
      } catch (error) {
        console.error('Error tracking hint:', error);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("formula", userFormula);
      formData.append("hints_used", JSON.stringify(Array.from(currentAttemptHints)));

      const response = await fetch(getApiUrl(`/api/problems/${problemId}/submit`), {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      const data = await response.json();
      console.log('Problem submission response:', data);

      if (!response.ok) {
        toast({
          title: "Submission Failed",
          description: data.message || "Failed to submit solution. Please try again.",
          variant: "destructive",
        });
        return;
      }

      if (data.success) {
        // Success flow
        if (problemData) {
          setProblemData({
            ...problemData,
            isCompleted: true
          });
        }
        setCurrentAttemptHints(new Set());
        setUserFormula("");
        setPotentialScore(data.score);
        setEarnedScore(data.score);
        setIsProblemCompleted(true);
        setShowCompletionModal(true);

        // Call onProblemComplete if it exists (for tutorial mode)
        if (tutorialMode && onProblemComplete) {
          onProblemComplete(data.score);
        }

        // Process any achievements in the response data
        if (data.new_achievements && data.new_achievements.length > 0) {
          console.log('Found new achievements in submission response:', data.new_achievements);
          handleNewAchievements(data);
        }
        
        // Also check for any new achievements by comparing with previous state
        // This is more reliable and will catch achievements awarded via background processes
        checkForAchievementsAfterCompletion();

        // Auto redirect after delay
        setTimeout(() => {
          setShowCompletionModal(false);
          navigate('/problems');
        }, 4500);
      } else {
        // Wrong answer
        toast({
          title: "Incorrect Solution",
          description: data.message || "Your answer is incorrect. Please try again.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast({
        title: "No File Selected",
        description: "Please select a file to upload.",
        variant: "default",
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(getApiUrl(`/files/upload_file/${problemId}`), {
        method: 'POST',
        credentials: 'include',
        body: formData
      });

      if (response.status === 401) {
        navigate('/auth');
        return;
      }

      if (response.ok) {
        toast({
          title: "Success",
          description: "File uploaded successfully!",
          variant: "default",
        });
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      toast({
        title: "Upload Failed",
        description: "Failed to upload file. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleFileDownload = async () => {
    // Function implementation for file download
    console.log("File download is currently disabled");
  };

  const handleCancelAttempt = () => {
    setCurrentAttemptHints(new Set());
    setPotentialScore(problemData?.maxScore || 0);
    setUserFormula("");
    setResultMessage("");
    navigate(returnPath);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !submitting && !isProblemCompleted) {
      event.preventDefault();
      const syntheticEvent = { preventDefault: () => {} } as React.FormEvent;
      handleSubmit(syntheticEvent);
    }
  };

  // Handle going back - use onFinish if in tutorial mode, otherwise navigate
  const handleBack = () => {
    if (tutorialMode && onFinish) {
      onFinish();
    } else {
      navigate(returnPath);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (showPremiumModal) {
    return (
      <PremiumModal
        open={showPremiumModal}
        onClose={() => {
          setShowPremiumModal(false);
          navigate(returnPath || '/problems');
        }}
        onUpgrade={() => navigate('/pricing')}
      />
    );
  }

  if (error) {
    return (
      <div className="p-8">
        <div className="mx-auto max-w-5xl">
          <CustomAlert variant="error">
            {error}
          </CustomAlert>
        </div>
      </div>
    );
  }

  if (!problemData) {
    return (
      <div className="p-8">
        <div className="mx-auto max-w-5xl">
          <CustomAlert variant="error">Problem not found</CustomAlert>
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialog open={showCompletionModal} onOpenChange={setShowCompletionModal}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl">
              <Trophy className="h-6 w-6 text-yellow-500" />
              Problem Completed!
            </DialogTitle>
            <DialogDescription className="text-center space-y-2">
              <p className="text-lg">
                Congratulations! You've solved the problem and earned:
              </p>
              <p className="text-3xl font-bold text-primary">
                {earnedScore} points
              </p>
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center gap-4 mt-6">
            <Button
              variant="outline"
              onClick={handleBack}
              className="w-full"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Return
            </Button>
            <Button
              onClick={() => {
                setShowCompletionModal(false);
                navigate('/problems');
              }}
              className="w-full"
            >
              More Problems
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white relative overflow-hidden transition-colors duration-200">
        <Header />
        
        <div className="pt-32">
          <div className="container mx-auto px-4">
            <div className="w-full max-w-7xl mx-auto">
              {/* Top section with title */}
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                  {problemData?.title}
                </h1>
                {/* Removed buttons from here */}
              </div>

              <div className="flex flex-col md:flex-row gap-6 relative">
                {/* Left Panel - Problem Description */}
                <div className="flex-grow md:w-3/5 rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 overflow-hidden">
                  {/* Problem Statement Section */}
                  <div className="p-6">
                    <p className="text-gray-600 dark:text-gray-300">
                      {problemData?.statement}
                    </p>
                    
                    {/* Add mini-sheet component if data is available */}
                    {problemData?.mini_sheet && (
                      <MiniSheet
                        cellRanges={problemData.mini_sheet.cell_ranges}
                        headers={problemData.mini_sheet.headers}
                        numberOfLines={problemData.mini_sheet.number_of_lines}
                        exampleData={problemData.mini_sheet.example_data}
                      />
                    )}
                  </div>

                  <div className="border-t border-gray-200 dark:border-gray-800" />

                  {/* Cell References Section */}
                  <div className="p-6">
                    <h2 className="text-xl font-semibold mb-4">Cell references:</h2>
                    {(() => {
                      // Function to extract cell references from ranges
                      const extractCellReferences = () => {
                        const cellRefsSet = new Set<string>(); // Use Set for automatic deduplication
                        
                        // If we have mini_sheet with cell_ranges
                        if (problemData?.mini_sheet?.cell_ranges) {
                          let ranges: string[] = [];
                          
                          // Handle both string and array formats
                          if (typeof problemData.mini_sheet.cell_ranges === 'string') {
                            try {
                              ranges = JSON.parse(problemData.mini_sheet.cell_ranges);
                            } catch (e) {
                              ranges = [problemData.mini_sheet.cell_ranges];
                            }
                          } else {
                            ranges = problemData.mini_sheet.cell_ranges;
                          }
                          
                          // Process each range
                          ranges.forEach(range => {
                            // Strip any quotes if present
                            const cleanRange = range.replace(/"/g, '');
                            
                            // Check if it's a range with colon
                            if (cleanRange.includes(':')) {
                              const [start, end] = cleanRange.split(':');
                              cellRefsSet.add(start);
                              cellRefsSet.add(end);
                            } else {
                              // Single cell reference
                              cellRefsSet.add(cleanRange);
                            }
                          });
                        }
                        
                        // Also include any explicit constraints
                        if (problemData?.constraints?.length) {
                          problemData.constraints.forEach(constraint => {
                            cellRefsSet.add(constraint.cell);
                          });
                        }
                        
                        return Array.from(cellRefsSet);
                      };
                      
                      const cellReferences = extractCellReferences();
                      
                      // If there are no cell references, show a message
                      if (cellReferences.length === 0) {
                        return <p className="text-gray-600 dark:text-gray-300">No cell references available.</p>;
                      }
                      
                      // Create two-column grid layout
                      return (
                        <div className="grid grid-cols-2 gap-2" id="cell-references-grid">
                          {cellReferences.map((cell, index) => (
                            <div key={index} className="flex items-center cell-reference-item">
                              <span className="inline-block w-1 h-1 rounded-full bg-gray-400 dark:bg-gray-600 mr-2"></span>
                              <span className="text-gray-600 dark:text-gray-300">{cell}</span>
                            </div>
                          ))}
                        </div>
                      );
                    })()}
                  </div>

                  {/* Hints Section - Added back */}
                  {validHints.length > 0 && (
                    <>
                      <div className="border-t border-gray-200 dark:border-gray-800" />
                      {/* Hints Section */}
                      <div className="p-6" id="hints-section">
                        <h2 className="text-xl font-semibold mb-4" id="hints-title">Hints</h2>
                        <div className="space-y-3">
                          {validHints.map((hint, index) => (
                            <div key={index} className="hint-container">
                              <Button
                                onClick={() => handleHintExpand(index)}
                                variant="ghost"
                                className="flex items-center justify-between w-full text-left p-3 rounded-lg border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors"
                              >
                                <span className="flex items-center">
                                  <LightbulbIcon className="h-4 w-4 mr-2 text-[#3eb489] dark:text-[#4ade80]" />
                                  Hint {index + 1}
                                </span>
                                <span className="text-sm text-gray-500">
                                  -{calculateHintPenalty(index)} points
                                </span>
                              </Button>
                              {currentAttemptHints.has(index) && (
                                <div className="mt-2 p-4 rounded-lg bg-gray-50 dark:bg-gray-900/50 text-gray-700 dark:text-gray-300">
                                  {hint}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Right Panel Container Column */}
                <div className="md:w-2/5 md:flex md:flex-col">
                  {/* Controls Panel */}
                  <div className="flex items-center justify-between gap-4 mb-4">
                    <div style={{
                      opacity: 0.3,
                      filter: 'blur(2px)',
                      backgroundColor: theme === 'dark' ? 'rgba(15, 23, 42, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                    }}>
                      <Select value={formulaType} onValueChange={handleChange} disabled>
                        <SelectTrigger className="w-[180px] bg-transparent border-gray-200 dark:border-gray-800">
                          <SelectValue placeholder="Select formula type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="excel">Excel</SelectItem>
                          <SelectItem value="gsheets">Google Sheets</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div style={{
                      opacity: 0.3,
                      filter: 'blur(2px)',
                      backgroundColor: theme === 'dark' ? 'rgba(15, 23, 42, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                    }} className="flex gap-2">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                        id="file-upload"
                        disabled
                      />
                      <label htmlFor="file-upload">
                        <Button
                          variant="outline"
                          className="border-gray-200 dark:border-gray-800"
                          disabled
                        >
                          <Upload className="h-4 w-4 mr-2" />
                          Upload
                        </Button>
                      </label>
                      <Button
                        variant="outline"
                        onClick={handleFileDownload}
                        className="border-gray-200 dark:border-gray-800"
                        disabled
                      >
                        <Download className="h-4 w-4 mr-2" />
                        Download
                      </Button>
                    </div>
                  </div>

                  {/* Solution Area - explicitly smaller height */}
                  <div className="rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 p-6 md:h-[450px]">
                    <div className="w-full h-full flex flex-col justify-center space-y-4 overflow-y-auto">
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        Current potential score: {potentialScore} points
                        {currentAttemptHints.size > 0 && ` • Hints used: ${currentAttemptHints.size}`}
                      </div>

                      <div className="space-y-4">
                        <textarea
                          id="formula-editor"
                          value={userFormula}
                          onChange={(e) => setUserFormula(e.target.value)}
                          onKeyPress={handleKeyPress}
                          placeholder="Please enter your formula"
                          rows={7}
                          className="w-full bg-white dark:bg-gray-900 rounded-md border border-gray-300 dark:border-gray-700 p-3 font-mono text-sm text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-[#3eb489] dark:focus:ring-[#4ade80] focus:border-transparent placeholder-gray-500 dark:placeholder-gray-400"
                        />

                        <div className="flex justify-end gap-4">
                          <Button
                            variant="outline"
                            onClick={handleCancelAttempt}
                            className="border-gray-200 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800"
                          >
                            Cancel
                          </Button>
                          <Button
                            id="submit-button"
                            onClick={handleSubmit}
                            disabled={isProblemCompleted}
                            className={`${
                              isProblemCompleted
                                ? 'bg-gray-400'
                                : 'bg-[#3eb489] hover:bg-[#35a07a] dark:bg-[#4ade80] dark:hover:bg-[#22c55e]'
                            } text-white`}
                          >
                            {isProblemCompleted ? "Completed" : "Submit"}
                          </Button>
                        </div>
                      </div>

                      {resultMessage && (
                        <CustomAlert
                          variant={typeof resultMessage === 'string' && resultMessage.toLowerCase().includes('correct')
                            ? 'success'
                            : 'error'}
                        >
                          {resultMessage}
                        </CustomAlert>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PremiumModal
          open={showPremiumModal}
          onClose={() => {
            setShowPremiumModal(false);
            navigate(returnPath || '/problems');
          }}
          onUpgrade={() => navigate('/pricing')}
        />
      </div>
    </>
  );
};

export default Editor;