import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChallengesList } from '../components/challenges/challenges-list';
import { Loading } from '../components/ui/loading';
import { CustomAlert } from '../components/ui/custom-alert';
import Header from '../components/Header';
import { getApiUrl } from '../config/env';
import { Trophy } from 'lucide-react';
import { handleNewAchievements } from '../utils/achievement-handler';
import { checkForNewAchievements } from '../utils/achievement-tracker';

interface Challenge {
  challenge_id: number;
  challenge_type: 'daily' | 'weekly' | 'special';
  title: string;
  description: string;
  points: number;
  icon_url: string;
  start_time: string;
  end_time: string;
  is_active: boolean;
  problem: {
    problem_id: number;
    problem_title: string;
    level: string;
  };
  submission?: {
    completed: boolean;
    score: number;
    start_timestamp: string;
    end_timestamp: string;
  };
}

export default function Challenges() {
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchChallenges = async () => {
    try {
      const response = await fetch(getApiUrl('/api/challenges/current'), {
        credentials: 'include'
      });

      if (response.status === 401) {
        navigate('/auth');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch challenges');
      }

      const data = await response.json();
      setChallenges(data.challenges);

      // Process any achievements in the response data
      handleNewAchievements(data);
      
      // Also check for any recently earned achievements
      // This is particularly important after completing challenges
      checkForNewAchievements();
    } catch (err) {
      setError('Failed to load challenges. Please try again later.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, [navigate, location.key]);

  const handleStartChallenge = (challengeId: number) => {
    const challenge = challenges.find(c => c.challenge_id === challengeId);
    if (challenge) {
      navigate(`/problems/${challenge.problem.problem_id}`, {
        state: { returnPath: '/challenges' }
      });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
      <Header />
      <div className="container mx-auto px-4 py-8">
        {error ? (
          <CustomAlert variant="error" className="max-w-2xl mx-auto">
            {error}
          </CustomAlert>
        ) : (
          <ChallengesList 
            challenges={challenges}
            onStartChallenge={handleStartChallenge}
            className="max-w-4xl mx-auto"
          />
        )}
      </div>
    </div>
  );
} 