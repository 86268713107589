import React, { useState } from 'react';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Card, CardContent } from './ui/card';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircle } from 'lucide-react';
import { getApiUrl } from '../config/env';

// Since we don't have the form components from shadcn, let's define simpler versions
const FormItem = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-2">{children}</div>
);

const FormLabel = ({ children }: { children: React.ReactNode }) => (
  <label className="text-sm font-medium leading-none">{children}</label>
);

const FormControl = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-2">{children}</div>
);

const FormDescription = ({ children }: { children: React.ReactNode }) => (
  <p className="text-sm text-muted-foreground mt-1">{children}</p>
);

const FormMessage = ({ children }: { children?: React.ReactNode }) => (
  <p className="text-sm text-destructive mt-1">{children}</p>
);

const Form = ({ children }: { children: React.ReactNode }) => <div>{children}</div>;

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="p-6 pb-0 space-y-1">{children}</div>
);

const CardTitle = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-2xl font-semibold leading-none tracking-tight">{children}</h3>
);

const CardDescription = ({ children }: { children: React.ReactNode }) => (
  <p className="text-sm text-muted-foreground">{children}</p>
);

const usernameSchema = z.object({
  username: z
    .string()
    .min(3, { message: 'Username must be at least 3 characters long' })
    .max(30, { message: 'Username cannot exceed 30 characters' })
    .regex(/^[a-zA-Z0-9_-]+$/, {
      message: 'Username can only contain letters, numbers, underscores, and hyphens',
    }),
});

type UsernameFormValues = z.infer<typeof usernameSchema>;

interface UsernameSetupProps {
  onComplete: (username: string) => void;
}

export const UsernameSetup: React.FC<UsernameSetupProps> = ({ onComplete }) => {
  const session = useSessionContext() as SessionContextType;
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<UsernameFormValues>({
    resolver: zodResolver(usernameSchema),
    defaultValues: {
      username: '',
    },
  });

  const onSubmit = async (values: UsernameFormValues) => {
    if (session.loading || !session.accessTokenPayload) {
      return;
    }

    try {
      setError(null);
      setIsSubmitting(true);

      const userId = session.accessTokenPayload.sub;
      const response = await fetch(getApiUrl(`/api/users/${userId}/username`), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username: values.username }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to set username');
      }

      const data = await response.json();
      onComplete(data.username);
    } catch (error) {
      console.error('Error setting username:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>Choose your username</CardTitle>
        <CardDescription>
          Pick a unique username that will be displayed to other users.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <Form>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input 
                  placeholder="your-username" 
                  {...form.register('username')} 
                />
              </FormControl>
              <FormDescription>
                Your username must be 3-30 characters, and can only contain letters, numbers, underscores, and hyphens.
              </FormDescription>
              <FormMessage>
                {form.formState.errors.username?.message}
              </FormMessage>
            </FormItem>
            <Button type="submit" className="w-full" disabled={isSubmitting}>
              {isSubmitting ? 'Setting up...' : 'Continue'}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default UsernameSetup; 