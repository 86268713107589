import { showAchievementToast } from '../components/achievements/achievement-toast';

// Keep track of achievements we've already shown to avoid duplicates
const shownAchievements = new Set<string>();

/**
 * Helper function to check for and display achievement notifications
 * @param data API response data that might contain new achievements
 */
export function handleNewAchievements(data: any): void {
  // Log the data to understand its structure
  console.log('Checking for achievements in data:', data);
  
  // Different APIs might return achievements in different formats
  const achievementsArray = 
    data?.new_achievements || 
    data?.achievements || 
    data?.user_achievements || 
    [];
  
  if (!Array.isArray(achievementsArray) || achievementsArray.length === 0) {
    console.log('No achievements found in data');
    return;
  }
  
  console.log('Found achievements array:', achievementsArray);
  
  // Check for recently awarded achievements (within the last minute)
  // This works for both "achievements" coming from get /api/achievements and possibly user_achievements in other responses
  const recentlyAwarded = achievementsArray.filter(achievement => {
    // Skip if we don't have awarded info or if explicitly marked as not new
    if ((achievement.is_new === false) || (!achievement.awarded && !achievement.awarded_at)) {
      return false;
    }
    
    // If explicitly marked as new, include it
    if (achievement.is_new === true) {
      return true;
    }
    
    // Check if this was awarded recently
    if (achievement.awarded_at) {
      const awardedTime = new Date(achievement.awarded_at).getTime();
      const now = new Date().getTime();
      const isRecent = now - awardedTime < 60000; // Within the last minute
      
      // Create a unique ID for this achievement
      const achievementId = achievement.achievement_id || achievement.id;
      const uniqueId = `${achievementId}-${achievement.awarded_at}`;
      
      // Only show if recent and we haven't shown it before
      if (isRecent && !shownAchievements.has(uniqueId)) {
        shownAchievements.add(uniqueId);
        return true;
      }
    }
    
    return false;
  });
  
  if (recentlyAwarded.length > 0) {
    console.log('Found new/recent achievements:', recentlyAwarded);
    
    // Show toast notification for each achievement with a slight delay between them
    recentlyAwarded.forEach((achievement: any, index: number) => {
      setTimeout(() => {
        console.log('Showing toast for achievement:', achievement);
        showAchievementToast(achievement);
      }, index * 1000); // 1 second delay between each toast
    });
  } else {
    console.log('No new/recent achievements found');
  }
} 