import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';
import { Button } from './ui/button';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from './ui/dialog';

interface PremiumModalProps {
    open: boolean;
    onClose: () => void;
    onUpgrade?: () => void;
}

const PremiumModal: React.FC<PremiumModalProps> = ({ open, onClose, onUpgrade }) => {
    const navigate = useNavigate();

    const handleUpgrade = () => {
        if (onUpgrade) {
            onUpgrade();
        } else {
            navigate('/pricing');
            onClose();
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-md">
                <DialogHeader className="flex flex-col items-center gap-4">
                    <div className="rounded-full bg-primary/10 p-3">
                        <Lock className="h-6 w-6 text-primary" />
                    </div>
                    <DialogTitle>Premium Feature</DialogTitle>
                    <DialogDescription className="text-center">
                        This problem is only available to premium users.
                        <span className="block mt-1 text-muted-foreground">
                            Upgrade to premium to access all problems and features!
                        </span>
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter className="flex flex-col-reverse sm:flex-row sm:justify-center sm:space-x-2 sm:space-y-0 space-y-2">
                    <Button
                        variant="outline"
                        onClick={onClose}
                    >
                        Maybe Later
                    </Button>
                    <Button
                        onClick={handleUpgrade}
                        className="bg-primary hover:bg-primary/90"
                    >
                        Upgrade to Premium
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default PremiumModal; 