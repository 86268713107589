import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Header from "../components/Header";
import { getApiUrl } from '../config/env';
import { Skeleton } from "../components/ui/skeleton";
import { handleNewAchievements } from '../utils/achievement-handler';
import { triggerTestAchievement, resetAchievementNotifications } from '../utils/achievement-tracker';

interface Problem {
  id: number;
  title: string;
  difficulty: string;
  isPremium: boolean;
  isCompleted: boolean;
  isAvailable: boolean;
  locked?: boolean;
  type?: string;
  score?: number;
  category?: string;
}

type DifficultyLevel = "" | "easy" | "medium" | "hard";

const PROBLEMS_PER_PAGE = 10;

const ProblemList = () => {
  const [problems, setProblems] = useState<Problem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [difficulty, setDifficulty] = useState<DifficultyLevel>("");
  const [sortBy, setSortBy] = useState<string>("problem_id");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [showFreeOnly, setShowFreeOnly] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  // Add state to track first load
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const fetchProblems = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams({
        ...(difficulty && { difficulty }),
        sort_by: sortBy,
        order: sortOrder,
        show_completed: showCompleted.toString(),
        show_free_only: showFreeOnly.toString(),
        page: currentPage.toString(),
        limit: PROBLEMS_PER_PAGE.toString(),
      });

      const response = await fetch(
        getApiUrl(`/api/problems?${queryParams}`),
        {
          credentials: "include",
        }
      );

      if (response.status === 401) {
        navigate("/auth");
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to fetch problems");
      }

      const data = await response.json();
      setProblems(data.problems);
      setTotalCount(data.total);
      setTotalPages(Math.ceil(data.total / PROBLEMS_PER_PAGE));
      
      // Check for new achievements in the response
      handleNewAchievements(data);
      
      // Set first load to false after data is loaded
      setIsFirstLoad(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to fetch problems");
      console.error("Error:", err);
      
      // Set first load to false even on error
      setIsFirstLoad(false);
    } finally {
      setLoading(false);
    }
  }, [difficulty, sortBy, sortOrder, showCompleted, showFreeOnly, currentPage, navigate]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when filters change
  }, [difficulty, sortBy, sortOrder, showCompleted, showFreeOnly]);

  useEffect(() => {
    fetchProblems();
  }, [fetchProblems]);

  const getDifficultyColor = (difficulty: string): string => {
    switch (difficulty?.toLowerCase()) {
      case "easy":
        return "text-[#4ade80]";
      case "medium":
        return "text-[#fb923c]";
      case "hard":
        return "text-[#e879f9]";
      case "expert":
        return "text-[#f43f5e]";
      default:
        return "text-gray-400";
    }
  };

  const handleSortClick = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Skeleton loader component for problem cards
  const ProblemSkeleton = () => (
    <div className="relative overflow-hidden rounded-lg border border-gray-200 dark:border-gray-800 bg-white/80 dark:bg-gray-800/80 p-5">
      <div className="flex items-start justify-between">
        <div className="w-full">
          <Skeleton className="h-6 w-3/4 mb-2" />
          <div className="mt-1 flex items-center gap-2">
            <Skeleton className="h-4 w-16" />
            <Skeleton className="h-4 w-24" />
          </div>
        </div>
        <Skeleton className="h-6 w-24" />
      </div>
    </div>
  );

  if (isFirstLoad) {
    // Show skeleton loaders during first load
    return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        
        <div className="max-w-5xl mx-auto space-y-6 p-8 pt-24 relative">
          {/* Filters - Skeleton */}
          <div className="flex items-center justify-between rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 p-4">
            <div className="flex items-center gap-4">
              <Skeleton className="h-10 w-36" />
              <Skeleton className="h-10 w-36" />
            </div>
            <Skeleton className="h-6 w-28" />
          </div>

          {/* Problem List - Skeletons */}
          <div className="grid grid-cols-1 gap-4">
            {Array(PROBLEMS_PER_PAGE).fill(0).map((_, index) => (
              <ProblemSkeleton key={index} />
            ))}
          </div>

          {/* Bottom Pagination - Skeleton */}
          <div className="flex items-center justify-between px-2 py-4 bg-white/50 dark:bg-gray-900/50 rounded-lg border border-gray-200 dark:border-gray-800">
            <Skeleton className="h-6 w-52" />
            <div className="flex items-center gap-2">
              <Skeleton className="h-8 w-8" />
              <Skeleton className="h-8 w-8" />
              <Skeleton className="h-8 w-8" />
              <Skeleton className="h-8 w-8" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading && !isFirstLoad) {
    // Show spinner for subsequent loads
    return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
          <div className="flex items-center justify-center h-[calc(100vh-64px)]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#3eb489] dark:border-[#4ade80]"></div>
          </div>
        </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-transparent p-8">
        <div className="mx-auto max-w-5xl">
          <div className="rounded-lg border border-red-500/20 bg-red-500/5 p-4 text-red-500">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white relative overflow-hidden transition-colors duration-200">
        <Header />
        
        <div className="max-w-5xl mx-auto space-y-6 p-8 pt-24 relative">
          {/* Dev Test Button - Only shown in development */}
          {process.env.NODE_ENV === 'development' && (
            <div className="flex justify-end mb-4 gap-2">
              <Button 
                variant="outline"
                onClick={() => triggerTestAchievement()}
                className="text-xs bg-yellow-500/10 text-yellow-600 dark:text-yellow-400 border-yellow-400/30"
              >
                Test Achievement Toast
              </Button>
              <Button 
                variant="outline"
                onClick={() => resetAchievementNotifications()}
                className="text-xs bg-red-500/10 text-red-600 dark:text-red-400 border-red-400/30"
              >
                Reset Achievement History
              </Button>
            </div>
          )}

          {/* Filters */}
          <div className="flex items-center justify-between rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-800/50 p-4">
            <div className="flex items-center gap-4">
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value as DifficultyLevel)}
                className="rounded-md border border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 px-3 py-2 text-sm text-gray-900 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#3eb489] dark:focus:ring-[#4ade80]"
              >
                <option value="">All Levels</option>
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>

              <select
                value={showCompleted.toString()}
                onChange={(e) => setShowCompleted(e.target.value === "true")}
                className="rounded-md border border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 px-3 py-2 text-sm text-gray-900 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#3eb489] dark:focus:ring-[#4ade80]"
              >
                <option value="false">Uncompleted Only</option>
                <option value="true">Show All</option>
              </select>

              <select
                value={showFreeOnly.toString()}
                onChange={(e) => setShowFreeOnly(e.target.value === "true")}
                className="rounded-md border border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 px-3 py-2 text-sm text-gray-900 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#3eb489] dark:focus:ring-[#4ade80]"
              >
                <option value="false">All Problems</option>
                <option value="true">Free Only</option>
              </select>
            </div>

            {/* Top Pagination Info */}
            <div className="text-sm text-gray-700 dark:text-gray-300">
              Page {currentPage} of {totalPages}
            </div>
          </div>

          {/* Problem List */}
          <div className="grid grid-cols-1 gap-4">
            {problems.map((problem) => (
              <div
                key={problem.id}
                className={`relative overflow-hidden rounded-lg border ${
                  problem.locked 
                    ? 'border-amber-200 dark:border-amber-800 bg-amber-50 dark:bg-amber-900/20'
                    : problem.isCompleted
                    ? 'border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/20'
                    : 'border-gray-200 dark:border-gray-800 bg-white/80 dark:bg-gray-800/80'
                } p-5 transition-all hover:shadow-md ${
                  !problem.locked ? 'cursor-pointer' : 'cursor-default'
                }`}
                onClick={() => {
                  if (!problem.locked) {
                    navigate(`/problems/${problem.id}`);
                  } else {
                    // Navigate to pricing for locked problems
                    navigate('/pricing');
                  }
                }}
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-lg font-semibold">{problem.title}</h3>
                    <div className="mt-1 flex items-center gap-2 text-sm">
                      <span className={`font-medium ${getDifficultyColor(problem.difficulty)}`}>
                        {problem.difficulty}
                      </span>
                      {problem.type && (
                        <span className="text-gray-500 dark:text-gray-400">• {problem.type}</span>
                      )}
                      {problem.score && (
                        <span className="text-gray-500 dark:text-gray-400">
                          • {problem.score} points
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {problem.locked && (
                      <div className="rounded-full bg-amber-100 dark:bg-amber-800 px-2.5 py-0.5 text-xs font-medium text-amber-800 dark:text-amber-100">
                        Premium
                      </div>
                    )}
                    {problem.isCompleted && (
                      <div className="rounded-full bg-green-100 dark:bg-green-800 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:text-green-100">
                        Completed
                      </div>
                    )}
                  </div>
                </div>
                
                {problem.locked && (
                  <div className="mt-2 flex items-center justify-between">
                    <p className="text-sm text-amber-600 dark:text-amber-400">
                      Unlock this problem with a premium subscription
                    </p>
                    <Button
                      size="sm"
                      variant="outline"
                      className="border-amber-600 text-amber-600 hover:bg-amber-100 dark:border-amber-500 dark:text-amber-500 dark:hover:bg-amber-900/20"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/pricing');
                      }}
                    >
                      Upgrade
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {problems.length === 0 && (
            <div className="rounded-lg border border-gray-200 dark:border-gray-800 bg-white/80 dark:bg-gray-800/80 p-8 text-center">
              <p className="text-gray-500 dark:text-gray-400">No problems found matching your filters.</p>
            </div>
          )}

          {/* Bottom Pagination Controls */}
          <div className="flex items-center justify-between px-2 py-4 bg-white/50 dark:bg-gray-900/50 rounded-lg border border-gray-200 dark:border-gray-800">
            <div className="text-sm text-gray-700 dark:text-gray-300">
              Showing{" "}
              <span className="font-medium">
                {totalCount === 0 ? 0 : ((currentPage - 1) * PROBLEMS_PER_PAGE) + 1}
              </span>
              {" "}-{" "}
              <span className="font-medium">
                {Math.min(currentPage * PROBLEMS_PER_PAGE, totalCount)}
              </span>
              {" "}of{" "}
              <span className="font-medium">{totalCount}</span> problems
            </div>
            
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="h-8 w-8 p-0"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter(page => {
                  const distance = Math.abs(page - currentPage);
                  return distance === 0 || distance === 1 || page === 1 || page === totalPages;
                })
                .map((page, index, array) => (
                  <React.Fragment key={page}>
                    {index > 0 && array[index - 1] !== page - 1 && (
                      <span className="text-gray-500 dark:text-gray-400">...</span>
                    )}
                    <Button
                      variant={currentPage === page ? "default" : "outline"}
                      size="sm"
                      onClick={() => handlePageChange(page)}
                      className={`h-8 w-8 p-0 ${
                        currentPage === page 
                          ? "bg-[#3eb489] dark:bg-[#4ade80] text-white hover:bg-[#3eb489]/90 dark:hover:bg-[#4ade80]/90" 
                          : "hover:bg-gray-100 dark:hover:bg-gray-800"
                      }`}
                    >
                      {page}
                    </Button>
                  </React.Fragment>
                ))}
              
              <Button
                variant="outline"
                size="sm"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="h-8 w-8 p-0"
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ProblemList; 