import { getApiUrl } from '../config/env';
import { showAchievementToast } from '../components/achievements/achievement-toast';

// Define achievement interfaces
interface Achievement {
  achievement_id: number;
  name: string;
  description: string;
  badge_color: string;
  icon_url: string;
  points: number;
  awarded?: boolean;
  awarded_at?: string | null;
  is_new?: boolean;
  notification_shown?: boolean;
}

// Store the user's current achievements
interface TrackedAchievement {
  achievement_id: number;
  name: string;
  description: string;
  badge_color: string;
  icon_url: string;
  points: number;
}

// Store both the set of achievement IDs and the full data
let userAchievements: { 
  idSet: Set<number>,
  data: TrackedAchievement[]
} = {
  idSet: new Set<number>(),
  data: []
};

/**
 * Mark an achievement notification as shown on the server
 * @param achievementId The achievement ID to mark as shown
 */
async function markAchievementShown(achievementId: number): Promise<void> {
  try {
    const response = await fetch(getApiUrl(`/api/achievements/${achievementId}/mark-shown`), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      console.error(`Failed to mark achievement ${achievementId} as shown: ${response.status}`);
      return;
    }

    const data = await response.json();
    console.log(`Successfully marked achievement ${achievementId} as shown:`, data);
  } catch (error) {
    console.error(`Error marking achievement ${achievementId} as shown:`, error);
  }
}

/**
 * Show a toast notification for an achievement and mark it as shown on the server
 */
function showAchievementNotification(achievement: Achievement): void {
  if (achievement.notification_shown) {
    console.log(`Achievement ${achievement.name} (ID: ${achievement.achievement_id}) already shown to user, skipping notification`);
    return;
  }
  
  console.log(`Showing notification for achievement: ${achievement.name} (ID: ${achievement.achievement_id})`);
  showAchievementToast(achievement);
  markAchievementShown(achievement.achievement_id);
}

/**
 * Initialize the achievement tracker by loading the user's current achievements
 * Call this when the app loads or when the user logs in
 */
export async function initializeAchievementTracker(): Promise<void> {
  try {
    console.log('Initializing achievement tracker...');
    const response = await fetch(getApiUrl('/api/achievements'), {
      credentials: 'include'
    });

    if (!response.ok) {
      console.error('Failed to initialize achievement tracker:', response.status);
      return;
    }

    const data = await response.json();
    if (!data.achievements || !Array.isArray(data.achievements)) {
      console.error('Invalid achievements data format:', data);
      return;
    }

    // Check for any achievements marked as new
    const newAchievements = data.achievements.filter((a: Achievement) => a.is_new === true && a.awarded && !a.notification_shown);
    
    if (newAchievements.length > 0) {
      console.log('Found new achievements during initialization:', newAchievements);
      // Show notifications for new achievements
      newAchievements.forEach((achievement: Achievement, index: number) => {
        setTimeout(() => {
          showAchievementNotification(achievement);
        }, index * 1000); // 1 second delay between each toast
      });
    }

    // Filter to only awarded achievements
    const awardedAchievements = data.achievements.filter((a: Achievement) => a.awarded);
    
    // Store the initial set of achievements
    userAchievements = {
      idSet: new Set(awardedAchievements.map((a: Achievement) => a.achievement_id)),
      data: awardedAchievements.map((a: Achievement) => ({
        achievement_id: a.achievement_id,
        name: a.name,
        description: a.description,
        badge_color: a.badge_color,
        icon_url: a.icon_url,
        points: a.points
      }))
    };
    
    console.log(`Initial achievement tracking setup complete. User has ${userAchievements.data.length} achievements.`);
  } catch (error) {
    console.error('Error initializing achievement tracker:', error);
  }
}

/**
 * Check for new achievements by comparing the current set with what's on the server
 * Call this after completing an action that might award achievements
 */
export async function checkForNewAchievements(): Promise<void> {
  try {
    // If we haven't initialized yet, do that first
    if (userAchievements.data.length === 0) {
      await initializeAchievementTracker();
      return; // No need to continue - we've just loaded the initial state
    }
    
    console.log('Checking for new achievements...');
    const response = await fetch(getApiUrl('/api/achievements'), {
      credentials: 'include'
    });

    if (!response.ok) {
      console.error('Failed to check for new achievements:', response.status);
      return;
    }

    const data = await response.json();
    if (!data.achievements || !Array.isArray(data.achievements)) {
      console.error('Invalid achievements data format:', data);
      return;
    }

    // Filter to only awarded achievements
    const currentAwardedAchievements = data.achievements.filter((a: Achievement) => a.awarded);
    
    // Find new achievements (those marked as new or not in our tracked set)
    const newAchievements = currentAwardedAchievements.filter(
      (a: Achievement) => (a.is_new === true || !userAchievements.idSet.has(a.achievement_id)) && !a.notification_shown
    );
    
    if (newAchievements.length > 0) {
      console.log('Found new achievements:', newAchievements);
      
      // Show notifications for new achievements
      newAchievements.forEach((achievement: Achievement, index: number) => {
        setTimeout(() => {
          showAchievementNotification(achievement);
        }, index * 1000); // 1 second delay between each toast
      });
      
      // Update our tracked achievements
      userAchievements = {
        idSet: new Set(currentAwardedAchievements.map((a: Achievement) => a.achievement_id)),
        data: currentAwardedAchievements.map((a: Achievement) => ({
          achievement_id: a.achievement_id,
          name: a.name,
          description: a.description,
          badge_color: a.badge_color,
          icon_url: a.icon_url,
          points: a.points
        }))
      };
    } else {
      console.log('No new achievements found');
    }
  } catch (error) {
    console.error('Error checking for new achievements:', error);
  }
}

/**
 * Call this after completing a problem or challenge
 */
export function checkForAchievementsAfterCompletion(): void {
  // Wait a short time to ensure the backend has processed the achievement
  setTimeout(() => {
    checkForNewAchievements();
  }, 1500); // 1.5 second delay
}

/**
 * TEST FUNCTION: Trigger a test achievement toast
 * This is only for development testing
 */
export function triggerTestAchievement(): void {
  const testAchievement = {
    achievement_id: 999,
    name: "Test Achievement",
    description: "This is a test achievement to check the toast styling",
    badge_color: "#FFD700", // Gold color
    icon_url: "",
    points: 50,
    is_new: true,
    notification_shown: false
  };
  
  console.log('Triggering test achievement toast');
  showAchievementNotification(testAchievement);
}

/**
 * ADMIN FUNCTION: Reset all achievement notifications for testing
 * This makes a request to reset the notification_shown flags in the database
 */
export async function resetAchievementNotifications(): Promise<void> {
  try {
    console.log('Attempting to reset all achievement notifications...');
    
    // This would need to be implemented on the backend
    const response = await fetch(getApiUrl('/api/admin/reset-achievement-notifications'), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Failed to reset achievement notifications:', response.status);
      return;
    }
    
    const data = await response.json();
    console.log('Reset achievement notifications response:', data);
    
    // Refresh achievements to get the updated notification status
    await checkForNewAchievements();
  } catch (error) {
    console.error('Error resetting achievement notifications:', error);
  }
} 