import React, { useState, useEffect } from 'react';
import { Trophy, Star, Award, Code, UserRound, Settings, Crown, Edit } from 'lucide-react';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Card, CardContent } from "../components/ui/card";
import Header from '../components/Header';
import { getApiUrl } from '../config/env';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useState as useReactState } from 'react';
import UsernameSetup from '../components/username-setup';
import { handleNewAchievements } from '../utils/achievement-handler';

interface Achievement {
  id: number;
  name: string;
  description: string;
  icon_url: string;
  badge_color: string;
  points: number;
  awarded_at: string;
}

interface Problem {
  id: string;
  title: string;
  points: number;
  completed_at: string;
}

interface ProfileData {
  id: string;
  username: string;
  email: string;  
  role: string;
  total_score: number;
  solved_problems: Problem[];
  achievements: Achievement[];
  avatar_url: string;
}

const UserProfile = () => {
  const session = useSessionContext() as SessionContextType;
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEditingUsername, setIsEditingUsername] = useReactState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!session.loading && session.accessTokenPayload) {
        try {
          const userId = session.accessTokenPayload.sub;
          const profileResponse = await fetch(getApiUrl(`/api/users/${userId}/profile`), {
            credentials: 'include'
          });

          if (!profileResponse.ok) {
            throw new Error('Failed to fetch user profile');
          }

          const data = await profileResponse.json();
          console.log('Profile data received:', data);
          setProfileData(data);
          
          // Check for new achievements in the profile fetch response
          handleNewAchievements(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          setProfileData(null);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfile();
  }, [session, navigate]);

  const handleUsernameUpdate = (username: string) => {
    if (profileData) {
      setProfileData({
        ...profileData,
        username
      });
      setIsEditingUsername(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <div className="text-xl text-muted-foreground">Profile not found</div>
        </div>
      </div>
    );
  }

  return (
      <div className="min-h-screen bg-transparent text-gray-900 dark:text-white transition-colors">
        <Header />
        <div className="min-h-[calc(100vh-64px)] flex items-center justify-center p-6">
          <div className="w-full max-w-4xl">
            <div className="rounded-lg border border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-900/50 p-8">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-bold flex items-center gap-2">
                  <UserRound className="h-6 w-6 text-primary" />
                  Profile
                </h2>
                <button className="text-muted-foreground hover:text-foreground transition-colors">
                  <Settings className="h-5 w-5" />
                </button>
              </div>

              <div className="grid gap-6 md:grid-cols-[1fr_2fr]">
                <div className="space-y-6">
                  <Card className="border border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-800">
                    <div className="flex flex-col items-center space-y-4 p-6">
                      <Avatar className="h-24 w-24">
                        <AvatarImage src={profileData.avatar_url} alt="User" />
                        <AvatarFallback>
                          {profileData.email.charAt(0).toUpperCase()}
                        </AvatarFallback>
                      </Avatar>
                      <div className="space-y-1 text-center">
                        <h3 className="text-xl font-semibold">
                          {profileData.username}
                          <Dialog open={isEditingUsername} onOpenChange={setIsEditingUsername}>
                            <DialogTrigger asChild>
                              <Button variant="ghost" size="icon" className="h-6 w-6 ml-2">
                                <Edit className="h-4 w-4" />
                              </Button>
                            </DialogTrigger>
                            <DialogContent>
                              <DialogHeader>
                                <DialogTitle>Change Username</DialogTitle>
                              </DialogHeader>
                              <UsernameSetup onComplete={handleUsernameUpdate} />
                            </DialogContent>
                          </Dialog>
                        </h3>
                        {profileData.role && profileData.role.toLowerCase() === 'premium' ? (
                          <div className="flex flex-col items-center">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gradient-to-r from-amber-500 to-amber-300 text-white mb-1">
                              <Crown className="h-3 w-3 mr-1" />
                              PREMIUM
                            </span>
                            <p className="text-sm text-muted-foreground">Premium Member</p>
                          </div>
                        ) : (
                          <p className="text-sm text-muted-foreground">Free User</p>
                        )}
                      </div>
                    </div>
                  </Card>

                  <Card className="border border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-800">
                    <CardContent className="pt-6">
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Trophy className="h-4 w-4 text-yellow-400" />
                            <span className="text-sm">Score</span>
                          </div>
                          <span className="font-semibold">{profileData.total_score.toLocaleString()}</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Code className="h-4 w-4 text-blue-400" />
                            <span className="text-sm">Problems Solved</span>
                          </div>
                          <span className="font-semibold">{profileData.solved_problems.length}</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Award className="h-4 w-4 text-amber-400" />
                            <span className="text-sm">Achievements</span>
                          </div>
                          <span className="font-semibold">{profileData.achievements.length}</span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className="space-y-6">
                  <Card className="border border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-800">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
                      <div className="space-y-4">
                        {profileData.solved_problems.slice(0, 5).map((problem) => (
                          <div key={problem.id} className="flex items-center gap-4 text-sm">
                            <div className="h-2 w-2 rounded-full bg-primary" />
                            <span className="text-muted-foreground">Solved {problem.title}</span>
                            <span className="ml-auto text-xs text-muted-foreground">
                              {new Date(problem.completed_at).toLocaleDateString()}
                            </span>
                          </div>
                        ))}
                        {profileData.solved_problems.length === 0 && (
                          <div className="text-sm text-muted-foreground text-center py-4">
                            No problems solved yet
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>

                  <Card className="border border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-800">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold mb-4">Achievements</h3>
                      <div className="grid gap-4 md:grid-cols-2">
                        {profileData.achievements.map((achievement) => (
                          <div
                            key={achievement.id}
                            className="flex items-center gap-3 rounded-lg border p-3 text-sm bg-white/50 dark:bg-gray-900/50"
                            style={{ borderColor: achievement.badge_color + '40' }}
                          >
                            {achievement.icon_url ? (
                              <img 
                                src={achievement.icon_url} 
                                alt={achievement.name}
                                className="h-8 w-8"
                                style={{ color: achievement.badge_color }}
                              />
                            ) : (
                              <Award 
                                className="h-8 w-8"
                                style={{ color: achievement.badge_color }}
                              />
                            )}
                            <div>
                              <p className="font-medium">{achievement.name}</p>
                              <p className="text-xs text-muted-foreground">
                                {achievement.description}
                              </p>
                              <p className="text-xs text-muted-foreground mt-1">
                                Earned {new Date(achievement.awarded_at).toLocaleDateString()}
                              </p>
                            </div>
                          </div>
                        ))}
                        {profileData.achievements.length === 0 && (
                          <div className="text-sm text-muted-foreground text-center py-4 md:col-span-2">
                            No achievements earned yet
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default UserProfile; 