/*
<ai_context>
  This file replaces the old CookiePreferences.js with a TypeScript/TSX equivalent.
  It allows the user to customize their cookie preferences (functional, analytics, marketing).
</ai_context>
*/

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { getCookieConsent, setCookieConsent, CookiePreferences as ICookiePreferences } from "./cookie-manager";

interface CookiePreferencesProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface CookieCategory {
  name: string;
  description: string;
  required: boolean;
}

const cookieCategories: Record<string, CookieCategory> = {
  essential: {
    name: "Essential",
    description: "Required for the website to function properly. Cannot be disabled.",
    required: true,
  },
  functional: {
    name: "Functional",
    description: "Enables enhanced functionality and personalization.",
    required: false,
  },
  analytics: {
    name: "Analytics",
    description: "Helps us improve performance and understand user interactions.",
    required: false,
  },
  marketing: {
    name: "Marketing",
    description: "Used to deliver relevant ads and track ad campaign performance.",
    required: false,
  },
};

const CookiePreferences: React.FC<CookiePreferencesProps> = ({ open, onClose, onSave }) => {
  const [preferences, setPreferences] = useState<ICookiePreferences>({
    essential: true,
    functional: false,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const savedPreferences = getCookieConsent();
    if (savedPreferences) {
      setPreferences({
        ...savedPreferences,
        essential: true, // always ensure essential is true
      });
    }
  }, [open]);

  const handleChange = (category: keyof ICookiePreferences) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (cookieCategories[category].required) return;
    setPreferences((prev) => ({
      ...prev,
      [category]: event.target.checked,
    }));
  };

  const handleSave = () => {
    const newPrefs = {
      ...preferences,
      essential: true,
    };
    setCookieConsent(newPrefs);
    onSave();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div">
          Cookie Preferences
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          We use cookies to enhance your experience, analyze site usage, and for marketing purposes. 
          Adjust your settings below. You can change these settings at any time.
        </Typography>
        <FormGroup>
          {Object.entries(cookieCategories).map(([key, category]) => {
            const typedKey = key as keyof ICookiePreferences;
            return (
              <Box key={key} sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(preferences[typedKey])}
                      onChange={handleChange(typedKey)}
                      disabled={category.required}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="subtitle2">
                        {category.name}
                        {category.required && (
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{ ml: 1, color: "text.secondary" }}
                          >
                            (Required)
                          </Typography>
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.description}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    alignItems: "flex-start",
                    mr: 0,
                    ".MuiFormControlLabel-label": { flex: 1 },
                  }}
                />
              </Box>
            );
          })}
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: "text.secondary",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            backgroundColor: "#3eb489",
            "&:hover": {
              backgroundColor: "#35a07a",
            },
          }}
        >
          Save Preferences
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookiePreferences;