import React, { useState } from 'react';
import { AchievementCard } from './achievement-card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Trophy, Search, Filter } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

interface Achievement {
  achievement_id: number;
  name: string;
  description: string;
  icon_url: string;
  badge_color: string;
  points: number;
  awarded: boolean;
  awarded_at: string | null;
}

interface AchievementsListProps {
  achievements: Achievement[];
  className?: string;
}

export function AchievementsList({ achievements, className }: AchievementsListProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState<'all' | 'awarded' | 'locked'>('all');

  const filteredAchievements = achievements.filter(achievement => {
    const matchesSearch = 
      achievement.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      achievement.description.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesFilter = 
      filterStatus === 'all' ||
      (filterStatus === 'awarded' && achievement.awarded) ||
      (filterStatus === 'locked' && !achievement.awarded);

    return matchesSearch && matchesFilter;
  });

  const totalPoints = achievements.reduce((sum, achievement) => 
    achievement.awarded ? sum + achievement.points : sum, 0
  );

  const possiblePoints = achievements.reduce((sum, achievement) => 
    sum + achievement.points, 0
  );

  return (
    <div className={className}>
      {/* Header with total points */}
      <div className="mb-8 p-6 rounded-lg bg-primary/5 border">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <Trophy className="w-6 h-6 text-primary" />
          Your Achievements
        </h2>
        <p className="text-muted-foreground">
          You've earned {totalPoints} out of {possiblePoints} possible points
        </p>
      </div>

      {/* Search and filter controls */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-muted-foreground" />
          <Input
            placeholder="Search achievements..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-9"
          />
        </div>
        <Select
          value={filterStatus}
          onValueChange={(value: 'all' | 'awarded' | 'locked') => setFilterStatus(value)}
        >
          <SelectTrigger className="w-full sm:w-[180px]">
            <Filter className="w-4 h-4 mr-2" />
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Achievements</SelectItem>
            <SelectItem value="awarded">Awarded Only</SelectItem>
            <SelectItem value="locked">Locked Only</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Achievement cards grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredAchievements.map((achievement) => (
          <AchievementCard
            key={achievement.achievement_id}
            achievement={achievement}
          />
        ))}
      </div>

      {/* Empty state */}
      {filteredAchievements.length === 0 && (
        <div className="text-center py-12">
          <Trophy className="w-12 h-12 text-muted-foreground mx-auto mb-4" />
          <h3 className="text-lg font-semibold mb-2">No achievements found</h3>
          <p className="text-muted-foreground">
            {searchQuery
              ? "No achievements match your search criteria"
              : "Start solving problems to earn achievements!"}
          </p>
        </div>
      )}
    </div>
  );
} 