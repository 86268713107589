import React from 'react';
import { Card, CardContent } from '../ui/card';
import { Trophy, Lock } from 'lucide-react';
import { cn } from '../../lib/utils';

interface Achievement {
  achievement_id: number;
  name: string;
  description: string;
  icon_url: string;
  badge_color: string;
  points: number;
  awarded: boolean;
  awarded_at: string | null;
}

interface AchievementCardProps {
  achievement: Achievement;
  className?: string;
}

export function AchievementCard({ achievement, className }: AchievementCardProps) {
  return (
    <Card 
      className={cn(
        'group relative overflow-hidden transition-all duration-300',
        achievement.awarded ? 'bg-white/50 dark:bg-gray-800/50' : 'bg-gray-100/50 dark:bg-gray-900/50',
        className
      )}
    >
      <div 
        className="absolute inset-0 opacity-10"
        style={{ 
          backgroundColor: achievement.badge_color,
          opacity: achievement.awarded ? 0.2 : 0.05 
        }}
      />
      
      <CardContent className="p-6">
        <div className="flex items-start justify-between gap-4">
          <div className="flex items-center gap-4">
            {achievement.icon_url ? (
              <img 
                src={achievement.icon_url} 
                alt={achievement.name}
                className="w-12 h-12 rounded-lg"
              />
            ) : (
              <div 
                className="w-12 h-12 rounded-lg flex items-center justify-center"
                style={{ backgroundColor: achievement.badge_color }}
              >
                <Trophy className="w-6 h-6 text-white" />
              </div>
            )}
            
            <div>
              <h3 className="font-semibold text-lg flex items-center gap-2">
                {achievement.name}
                {!achievement.awarded && (
                  <Lock className="w-4 h-4 text-gray-400" />
                )}
              </h3>
              <p className="text-sm text-muted-foreground">
                {achievement.description}
              </p>
              {achievement.awarded && achievement.awarded_at && (
                <p className="text-xs text-muted-foreground mt-1">
                  Earned on {new Date(achievement.awarded_at).toLocaleDateString()}
                </p>
              )}
            </div>
          </div>
          
          <div className="flex items-center gap-1 bg-primary/10 px-2 py-1 rounded text-primary text-sm font-medium">
            <Trophy className="w-4 h-4" />
            {achievement.points}
          </div>
        </div>
      </CardContent>
    </Card>
  );
} 