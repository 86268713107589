import React from 'react';
import { useSessionContext, SessionContextType } from 'supertokens-auth-react/recipe/session';
import { signOut } from 'supertokens-auth-react/recipe/thirdparty';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';

const AuthButtons: React.FC = () => {
    const session = useSessionContext() as SessionContextType;
    const navigate = useNavigate();

    if (session.loading) {
        return null;
    }

    async function handleSignOut() {
        await signOut();
        navigate('/');
    }

    if (!session.doesSessionExist) {
        return (
            <Button
                variant="ghost"
                onClick={() => navigate('/auth')}
                className="text-sm font-medium"
            >
                Sign In
            </Button>
        );
    }

    return (
        <Button
            variant="ghost"
            onClick={handleSignOut}
            className="text-sm font-medium"
        >
            Sign Out
        </Button>
    );
};

export default AuthButtons; 