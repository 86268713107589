/*
<ai_context>
  This file replaces the old cookieManager.js with a TypeScript equivalent,
  preserving all existing logic and adding strong typing for GDPR/US compliance.
</ai_context>
*/

export interface CookiePreferences {
  essential: boolean;
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
  timestamp?: string;
}

const COOKIE_CONSENT_KEY = "cookieConsent";

/**
 * Loads user cookie consent preferences from localStorage.
 * @returns The stored cookie preferences or null if none stored.
 */
export function getCookieConsent(): CookiePreferences | null {
  try {
    const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
    return consent ? (JSON.parse(consent) as CookiePreferences) : null;
  } catch (error) {
    console.error("Error reading cookie consent:", error);
    return null;
  }
}

/**
 * Saves the given preferences to localStorage and applies them.
 * @param preferences The user's cookie category preferences.
 */
export function setCookieConsent(preferences: CookiePreferences): void {
  try {
    const newPrefs: CookiePreferences = {
      ...preferences,
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(newPrefs));
    applyPreferences(newPrefs);
  } catch (error) {
    console.error("Error saving cookie consent:", error);
  }
}

/**
 * Applies the user's cookie category choices by removing or allowing cookies/scripts as needed.
 * @param preferences The user's cookie category preferences.
 */
export function applyPreferences(preferences: CookiePreferences): void {
  // We always consider essential cookies mandatory

  // If the user disabled functional cookies, remove them
  if (!preferences.functional) {
    removeCookies(["preferences", "problem_history"]);
  }

  // If the user disabled analytics cookies, remove analytics scripts
  if (!preferences.analytics) {
    removeAnalytics();
  } else {
    enableAnalytics();
  }

  // If the user disabled marketing cookies, remove marketing trackers
  if (!preferences.marketing) {
    removeMarketing();
  } else {
    enableMarketing();
  }
}

/**
 * Removes specified cookies by name.
 * @param cookieNames The array of cookie names to remove.
 */
export function removeCookies(cookieNames: string[]): void {
  cookieNames.forEach((name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  });
}

/**
 * Checks if the existing cookie consent is valid (i.e. not older than 12 months).
 * @returns True if consent is valid, false otherwise.
 */
export function isCookieConsentValid(): boolean {
  const consent = getCookieConsent();
  if (!consent || !consent.timestamp) return false;

  const consentDate = new Date(consent.timestamp);
  const now = new Date();
  const monthsDiff =
    (now.getFullYear() - consentDate.getFullYear()) * 12 +
    now.getMonth() -
    consentDate.getMonth();

  return monthsDiff < 12;
}

/**
 * Applies any previously saved cookie preferences from localStorage on load.
 */
export function initializeCookieConsent(): void {
  const consent = getCookieConsent();
  if (consent) {
    applyPreferences(consent);
  }
}

/** 
 * Removes analytics scripts if user doesn't consent to analytics.
 */
function removeAnalytics(): void {
  const gaScript = document.getElementById("ga-script");
  if (gaScript) {
    gaScript.remove();
  }
  // Additional logic to disable or remove analytics if needed
}

/** 
 * Enables analytics scripts if user consents to analytics.
 */
function enableAnalytics(): void {
  // Example: dynamically inject Google Analytics script if not present
  if (!document.getElementById("ga-script")) {
    const script = document.createElement("script");
    script.id = "ga-script";
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=YOUR_GA_TRACKING_ID";
    document.head.appendChild(script);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'YOUR_GA_TRACKING_ID');
    `;
    document.head.appendChild(inlineScript);
  }
}

/** 
 * Removes marketing scripts if user doesn't consent to marketing.
 */
function removeMarketing(): void {
  const marketingScript = document.getElementById("marketing-script");
  if (marketingScript) {
    marketingScript.remove();
  }
  // Additional logic for removing marketing trackers
}

/** 
 * Enables marketing trackers if user consents to marketing.
 */
function enableMarketing(): void {
  if (!document.getElementById("marketing-script")) {
    const script = document.createElement("script");
    script.id = "marketing-script";
    script.async = true;
    script.src = "https://marketing.example.com/marketing.js";
    document.head.appendChild(script);
  }
}