/*
<ai_context>
  This file replaces the old CookieConsent.js with a TypeScript/TSX equivalent.
  It prompts the user for cookie preferences, specifically for GDPR/US compliance.
</ai_context>
*/

import React, { useState, useEffect } from "react";
import { Snackbar, Button, Box, Typography, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";
import CookiePreferences from "./cookie-preferences";
import { Link as RouterLink } from "react-router-dom";
import { CookiePreferences as ICookiePreferences } from "./cookie-manager";

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "white",
    color: "black",
    maxWidth: "100%",
    width: "100%",
    padding: theme.spacing(0),
    borderTop: "1px solid #e0e0e0",
    borderRadius: 0,
    boxShadow: "0px -2px 8px rgba(0,0,0,0.3)",
  },
}));

const CookieConsent: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showPreferences, setShowPreferences] = useState<boolean>(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem("cookieConsent");
    if (!hasConsent) {
      setOpen(true);
    }

    // Expose cookie preferences globally for possible "Cookie Preferences" link
    (window as any).cookiePreferences = () => {
      setShowPreferences(true);
    };

    return () => {
      delete (window as any).cookiePreferences;
    };
  }, []);

  const handleAcceptAll = () => {
    const allPrefs: ICookiePreferences = {
      essential: true,
      functional: true,
      analytics: true,
      marketing: true,
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem("cookieConsent", JSON.stringify(allPrefs));
    setOpen(false);
    // Reload to re-initialize scripts
    window.location.reload();
  };

  const handleCustomize = () => {
    setShowPreferences(true);
    setOpen(false);
  };

  return (
    <>
      <StyledSnackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            px: 2,
            py: 2,
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            backgroundColor: "#1a1f2e",
            color: "white",
            boxShadow: "0px -4px 10px rgba(0,0,0,0.5)",
            border: "1px solid #333"
          }}
        >
          <Typography variant="body2" sx={{ maxWidth: { sm: "60%" }, color: "white" }}>
            We use cookies to enhance your experience and analyze our website traffic. By clicking "Accept All", you consent to our use of all cookies in accordance with our{" "}
            <MuiLink component={RouterLink} to="/privacy" sx={{ color: "#4ade80" }}>
              Privacy Policy
            </MuiLink>
            {" "}and{" "}
            <MuiLink component={RouterLink} to="/cookie-policy" sx={{ color: "#4ade80" }}>
              Cookie Policy
            </MuiLink>.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={handleCustomize}
              sx={{
                borderColor: "#4ade80",
                color: "#4ade80",
                "&:hover": {
                  borderColor: "#22c55e",
                  backgroundColor: "rgba(74, 222, 128, 0.1)",
                },
                flex: { xs: 1, sm: "none" },
              }}
            >
              Customize
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleAcceptAll}
              sx={{
                backgroundColor: "#4ade80",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#22c55e",
                },
                flex: { xs: 1, sm: "none" },
                color: "#121212"
              }}
            >
              Accept All
            </Button>
          </Box>
        </Box>
      </StyledSnackbar>

      <CookiePreferences
        open={showPreferences}
        onClose={() => setShowPreferences(false)}
        onSave={() => setShowPreferences(false)}
      />
    </>
  );
};

export default CookieConsent;